<template>
  <v-card>
    <v-card-title primary-title> {{ datos.total }} Checadas </v-card-title>
    <v-card-text>
      <pie-chart
        :chart-data="datos.checadas"
        :options="options"
        :height="250"
      ></pie-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import PieChart from "./../PieChart.js";
export default {
  name: "Checadas",
  props: ["datos"],
  components: { PieChart },
  data: () => ({
    options: {
      maintainAspectRatio: false
    }
  })
};
</script>
