/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "users";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  abilities: undefined,
  userAbilities: undefined,
  roles: undefined,
  userRoles: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_ABILITIES(state, payload) {
      state.abilities = payload;
    },
    SET_USER_ABILITIES(state, payload) {
      state.userAbilities = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_USER_ROLES(state, payload) {
      state.userRoles = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("SET_ROLES", resp.data.roles);
          commit("SET_ABILITIES", resp.data.abilities);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async disableUser({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, {
            icon: "success",
            timer: 1000
          });
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async restoreUser({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .put(`${url}/restore/${id}`)
        .then(resp => {
          window.swal(resp.data.message, {
            icon: "success",
            timer: 1000
          });
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    nuevo({ commit }) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_RESOURCE", {});
      router.push({ name: "user.create" }).catch(() => {});
    },
    async editUser({ commit, dispatch, state }, id) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      let user = state.collection.find(x => x.id == id),
        nombre = `Editando a ${user.nombre}`;
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data);
          router
            .push({ name: "user.edit", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async sendUser({ commit, dispatch, state }) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      let user = state.resource;
      if (!user.id) {
        await window.axios
          .post(url, user)
          .then(resp => {
            window.swal(resp.data.message, {
              icon: "success",
              timer: 1000
            });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "users" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${user.id}`, user)
          .then(resp => {
            window.swal(resp.data.message, {
              icon: "success",
              timer: 1000
            });
            commit("SET_COLLECTION", resp.data.collection);
            router.push({ name: "users" }).catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    },
    async abilities({ commit, dispatch, state }, id) {
      if (!state.collection) {
        router.push({ name: "users" }).catch(() => {});
      } else {
        commit("SET_LOADER", true);
        commit("SET_ERRORS", [], { root: true });
        await window.axios
          .get(`${url}/abilities/${id}`)
          .then(resp => {
            commit("SET_RESOURCE", resp.data.resource);
            commit("SET_USER_ABILITIES", resp.data.userAbilities);
            commit("SET_USER_ROLES", resp.data.userRoles);
            let nombre = `Permisos de ${state.resource.full_name}`;
            router
              .push({ name: "user.abilities", params: { id, nombre } })
              .catch(() => {});
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    }
  }
};
