/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "monitor";
const initialState = () => ({
  headers: undefined,
  collection: [],
  resource: undefined,
  loader: false,
  time: undefined,
  checadas: undefined,
  icoglo: undefined,
  resultados: undefined,
  ultimas: undefined,
  quemados: {},
  enRuta: {}
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_TIME(state, payload) {
      state.time = payload;
    },
    SET_CHECADAS(state, payload) {
      state.checadas = payload;
    },
    SET_ICOGLO(state, payload) {
      state.icoglo = payload;
    },
    SET_RESULTADOS(state, payload) {
      state.resultados = payload;
    },
    SET_ULTIMAS(state, payload) {
      state.ultimas = payload;
    },
    SET_QUEMADOS(state, payload) {
      state.quemados = payload;
    },
    SET_EN_RUTA(state, payload) {
      state.enRuta = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("SET_TIME", new Date());
          commit("SET_ICOGLO", resp.data.icoglo);
          commit("SET_RESULTADOS", resp.data.resultados);
          commit("SET_ULTIMAS", resp.data.ultimas);
          commit("SET_QUEMADOS", resp.data.quemados);
          commit("SET_EN_RUTA", resp.data.enRuta);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async getSalida({ commit, dispatch }, id) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      await window.axios
        .get(`salidas/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
          let nombre = `Detalle de la salida ${resp.data.resource.id}`;
          router
            .push({ name: "monitor.detail", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async updateChecada({ commit, dispatch, state }, id) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      let checada = state.checadas.find(x => x.id == id);
      await window.axios
        .post(`${url}/updateChecada/${id}`, checada)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async setObservaciones({ commit, dispatch, state }) {
      commit("SET_LOADER", true);
      let salida = state.resource;
      await window.axios
        .post(`${url}/setObservaciones/${salida.id}`, salida)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async noDioVuelta({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .post(`${url}/noDioVuelta/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async solicitar({ commit, dispatch }) {
      commit("SET_LOADER", true);
      await window.axios
        .post(`${url}/solicitar`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_ICOGLO", resp.data.icoglo);
          commit("SET_RESULTADOS", resp.data.resultados);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async eliminarSalida({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`salidas/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          dispatch("getAll");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async modificarChecada({ commit, dispatch, state }, id) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      let checada = state.checadas.find(x => x.id == id);
      console.log(checada);
      await window.axios
        .post(`${url}/updateChecada/${id}`, checada)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async setChecada({ commit, dispatch }, item) {
      console.log(item);
    }
  }
};
