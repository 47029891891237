import router from ".";
import Rutas from "./../views/rutas/index.vue";
import Formulario from "./../views/rutas/form.vue";

const routes = [
  {
    path: "/Rutas",
    name: "rutas",
    component: Rutas,
    meta: {
      name: "Control de Rutas"
    }
  },
  {
    path: "/Rutas/nueva",
    name: "ruta.create",
    component: Formulario,
    meta: {
      name: "Nueva Ruta"
    }
  },
  {
    path: "/Rutas/detalles/:id",
    name: "ruta.details",
    component: Formulario,
    meta: {
      name: "Detalle de Rutas"
    }
  },
  {
    path: "/Puntos-checado",
    name: "puntos",
    component: require("@/views/rutas/PuntosChecado.vue").default,
    meta: {
      name: "Puntos Checado"
    }
  },
  {
    path: "/Puntos-checado/nuevo",
    name: "puntos.create",
    component: require("@/views/rutas/FormPunto.vue").default,
    meta: {
      name: "Nuevo Punto de Checado"
    }
  },
  {
    path: "/Puntos-checado/editar/:id",
    name: "puntos.edit",
    component: require("@/views/rutas/FormPunto.vue").default,
    meta: {
      name: "Editar Punto de Checado"
    }
  }
];
export default routes;
