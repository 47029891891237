import SetBase from "./../views/checadores/setBase.vue";
import Salidas from "./../views/checadores/newSalidas.vue";
import Minutos from "./../views/checadores/minutos.vue";
import LLegadas from "./../views/checadores/llegadas.vue";
import Salida from "./../views/checadores/salida.vue";

const routes = [
  {
    path: "/Colocar-base",
    name: "setBase",
    component: SetBase,
    meta: {
      name: "Colocar Base"
    }
  },
  {
    path: "/Salidas-Checadores",
    name: "salidas.base",
    component: Salidas,
    meta: {
      name: "Salidas"
    }
  },
  {
    path: "/Checador-minutos",
    name: "minutos.checador",
    component: Minutos,
    meta: {
      name: "Control de Turnos"
    }
  },
  {
    path: "/Llegadas-base",
    name: "llegadas.base",
    component: LLegadas,
    meta: {
      name: "Llegadas a la base"
    }
  },
  {
    path: "/Detalle-salida/:id",
    name: "salida.checador",
    component: Salida,
    meta: {
      name: "Detalle de la Salida"
    }
  }
];
export default routes;
