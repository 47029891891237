<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <div v-if="verificado">
      <v-form @submit.prevent="update">
        <v-card-text>
          <div class="mt-3">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Usuario"
                  v-model="user.user"
                  :error-messages="errors.user"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Nombre"
                  v-model="user.nombre"
                  :error-messages="errors.nombre"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Apellidos"
                  v-model="user.apellidos"
                  :error-messages="errors.apellidos"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="email"
                  label="Correo"
                  v-model="user.email"
                  :error-messages="errors.email"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Teléfono"
                  v-model="user.telefono"
                  :error-messages="errors.telefono"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="password"
                  label="Nueva Contraseña"
                  v-model="user.newPassword"
                  :error-messages="errors.newPassword"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="password"
                  label="Confirmar Nueva Contraseña"
                  v-model="user.newPassword_confirmation"
                  :error-messages="errors.newPassword"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit">Actualizar</v-btn>
          <v-btn
            color="error"
            @click="$router.push({ name: 'home' }).catch(() => {})"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-form>
    </div>
    <v-card-text v-else>
      <v-card class="mt-3" height="100%">
        <v-card-title>Ingresa tu contraseña para continuar</v-card-title>
        <v-form @submit.prevent="comprobarPwd">
          <v-card-text>
            <v-col cols="12" md="6">
              <v-text-field
                type="password"
                label="Contraseña"
                outlined
                dense
                v-model="pwd"
                :error-messages="errors.pwd"
              />
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" type="submit" :disabled="loading">
              enviar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Editar",
  data: () => ({
    verificado: undefined,
    pwd: undefined,
    loading: false,
    user: undefined
  }),
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    ...mapActions(["errores", "reset"]),
    ...mapMutations(["SET_ERRORS"]),
    comprobarPwd() {
      this.loading = true;
      this.SET_ERRORS([]);
      window.axios
        .post("auth/checkPass", { pwd: this.pwd })
        .then(resp => {
          if (resp.data.id) {
            this.verificado = true;
            this.user = resp.data;
          } else {
            this.sacarUsuario();
          }
        })
        .catch(e => this.errores(e))
        .finally(() => (this.loading = false));
    },
    sacarUsuario() {
      window.axios.post("auth/logout").then(() => {
        window.swal("Por Motivos de seguridad has sido sacado del sistema", {
          icon: "error",
          timer: 1000
        });
        localStorage.clear();
        this.reset();
        this.$router.push("/");
      });
    },
    update() {
      this.loading = true;
      this.SET_ERRORS([]);
      window.axios
        .post("auth/updateMe", this.user)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          localStorage.clear();
          this.reset();
          this.$router.push("/");
        })
        .catch(e => this.errores(e))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
