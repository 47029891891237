<template>
  <v-card class="mb-3" v-if="dashboard.porcentajes">
    <v-card-title primary-title>
      {{dashboard.porcentajes.salidas}} Salidas 
    </v-card-title>
    <v-card-text>
      <pie-chart
        :chart-data="dashboard.porcentajes"
        :options="options"
        :height="250"
      ></pie-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import PieChart from "./../PieChart.js";
export default {
  name: "Porcentaje",
  components: { PieChart },
  data: () => ({
    options: {
      maintainAspectRatio: false
    }
  }),
  computed: {
    ...mapState({
      dashboard: state => state.Auth.dashboard
    })
  }
};
</script>
