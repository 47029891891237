<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col> Importe Pendiente de recuperar {{ importe | moneda }} </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            v-if="userCan('crear-boletos')"
            :disabled="loading"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :items-per-page="perPage"
        :item-class="row_classes"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.pendiente`]="{ item }">
          {{ item.pendiente | moneda }}
        </template>
        <template v-slot:[`item.recuperado`]="{ item }">
          {{ item.recuperado | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="primary"
            :disabled="loading"
            v-if="item.actions.info"
            @click="detalles(item.id)"
          >
            <v-icon>mdi-information</v-icon>
          </v-btn>
          <v-btn
            icon
            color="success"
            :disabled="loading"
            v-if="item.actions.recuperar"
            @click="pagar(item)"
          >
            <v-icon>mdi-cash-check</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red"
            :disabled="loading"
            v-if="item.actions.delete"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="abonar" persistent max-width="600px">
      <v-card v-if="resource">
        <v-card-title>
          <span class="headline">
            Recuperar Dinero del Folio {{ resource.id | folio }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>Unidad {{ resource.unidad }}</v-col>
            <v-col>Operador: {{ resource.operador }}</v-col>
            <v-col>Importe Pendiente: {{ resource.pendiente | moneda }}</v-col>
          </v-row>
        </v-card-text>
        <v-form @submit.prevent="enviarPago">
          <v-card-text>
            <v-text-field
              outlined
              dense
              type="date"
              label="Fecha del pago"
              v-model="fecha"
              :max="hoy"
              :error-messages="errors.fecha"
            />
            <v-text-field
              outlined
              dense
              label="Importe a Abonar"
              type="number"
              step="0.01"
              v-model.number="importePagar"
              :error-messages="errors.importe"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="listo" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="cancelar">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Boletos",
  data: () => ({
    perPage: 15,
    search: undefined,
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Supervisor", value: "supervisor" },
      { text: "Boletos", value: "boletos", align: "end" },
      { text: "Unidad", value: "unidad" },
      { text: "Operador", value: "operador" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Recuperado", value: "recuperado", align: "end" },
      { text: "Pendiente", value: "pendiente", align: "end" },
      { text: "Usuario", value: "usuario" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    fecha: undefined,
    importePagar: undefined,
    abonar: undefined,
    resource: undefined
  }),
  computed: {
    ...mapState({
      loading: state => state.Boletos.loader,
      items: state => state.Boletos.collection,
      errors: state => state.errors
    }),
    pendientes() {
      if (!this.items) return [];
      return this.items.filter(x => x.pendiente > 0);
    },
    importe() {
      return window._.sumBy(this.pendientes, "pendiente");
    },
    listo() {
      if (this.loading) {
        return true;
      }

      if (
        this.fecha &&
        this.importePagar > 0 &&
        this.importePagar <= this.resource.pendiente
      )
        return false;
      return true;
    },
    hoy() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //January is 0!
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  mounted() {
    if (!this.items) {
      this.getAll();
    }
  },
  methods: {
    ...mapActions("Boletos", ["getAll", "detalles",'nuevo']),
    ...mapMutations("Boletos", ["SET_LOADER"]),
    ...mapMutations(["SET_ERRORS"]),
    ...mapActions(["errores"]),
    pagar(item) {
      this.resource = item;
      this.abonar = true;
    },
    cancelar() {
      this.resource = undefined;
      this.abonar = undefined;
      this.fecha = undefined;
      this.importePagar = undefined;
    },
    enviarPago() {
      this.SET_LOADER(true);
      this.SET_ERRORS([]);
      let data = { importe: this.importePagar, fecha: this.fecha };
      window.axios
        .post(`/boletos/abonar/${this.resource.id}`, data)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.$store.commit("Boletos/SET_COLLECTION", resp.data.collection);
          this.cancelar();
        })
        .catch(e => this.errores(e))
        .finally(() => this.SET_LOADER(false));
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar el pago folio ${item.folio}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.$store.commit("Boletos/SET_LOADER", true);
            this.$store.commit("SET_ERRORS", []);
            window.axios
              .delete(`/boletos/${item.id}`)
              .then(resp => {
                window.swal(resp.data.message, {
                  icon: "success",
                  timer: 1000
                });
                this.$store.commit(
                  "Boletos/SET_COLLECTION",
                  resp.data.collection
                );
              })
              .catch(e => this.$store.dispatch("errores", e))
              .finally(() => this.$store.commit("Boletos/SET_LOADER", false));
          }
        });
    }
  }
};
</script>
