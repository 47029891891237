<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Pendientes
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        hide-default-footer
        :headers="headers"
        :items="items"
        :items-per-page="items.length"
        :search.sync="search"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loading"
            @click="recibir(item)"
          >
            <v-icon>mdi-cash-usd-outline</v-icon>
          </v-btn>
          <v-btn icon color="red" :disabled="loading" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="mt-3">Importe Pendiente: {{ importe | moneda }}</div>
      <div class="mt-3">
        <span class="mr-4" v-for="checador in checadores" :key="checador"
          >{{ checador }} ({{ cantidadChecador(checador) }})
          {{ importeChecador(checador) | moneda }}</span
        >
      </div>
    </v-expansion-panel-content>
    <v-dialog v-model="pagar" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Recibir </span>
        </v-card-title>
        <v-form @submit.prevent="mandar">
          <v-card-text>
            Fecha: {{ turno.fecha | fechaLarga }} <br />
            Checador: {{ turno.checador }} <br />
            Importe Reportado: {{ turno.importe | moneda }}
            <v-row class="mt-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  step="0.01"
                  label="Dinero Entregado"
                  v-model.number="turno.dinero_entregado"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="turno.observaciones"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="pagar = false">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Pendientes",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Checador", value: "checador" },
      { text: "Base", value: "base" },
      { text: "Reportado", value: "importe", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    pagar: false,
    turno: {}
  }),
  computed: {
    ...mapState({
      pendientes: state => state.Minutos.collection,
      loading: state => state.Minutos.loader
    }),
    items() {
      if (!this.pendientes) return [];
      return this.pendientes.filter(x => x.importe);
    },
    importe() {
      return window._.sumBy(this.items, "importe");
    },
    checadores() {
      return [...new Set(this.items.map(x => x.checador))].sort();
    }
  },
  methods: {
    ...mapActions("Minutos", ["getAll", "eliminarTurno"]),
    ...mapMutations("Minutos", ["SET_COLLECTION"]),
    eliminar(item) {
      let dia = window
          .moment(item.fecha)
          .locale("es-MX")
          .format("dddd"),
        texto = window
          .moment(item.fecha)
          .locale("es-MX")
          .format("DD/MMM/YYYY");
      texto = texto.replace("ene.", "Ene");
      texto = texto.replace("feb.", "Feb");
      texto = texto.replace("mzo.", "Mzo");
      texto = texto.replace("abr.", "Abr");
      texto = texto.replace("may.", "May");
      texto = texto.replace("jun.", "Jun");
      texto = texto.replace("jul.", "Jul");
      texto = texto.replace("ago.", "Ago");
      texto = texto.replace("sep.", "Sep");
      texto = texto.replace("oct.", "Oct");
      texto = texto.replace("nov.", "Nov");
      texto = texto.replace("dic.", "Dic");
      dia = dia.replace("lunes", "Lunes");
      dia = dia.replace("martes", "Martes");
      dia = dia.replace("miércoles", "Miércoles");
      dia = dia.replace("jueves", "Jueves");
      dia = dia.replace("viernes", "Viernes");
      dia = dia.replace("sábado", "Sábado");
      dia = dia.replace("domingo", "Domingo");
      let mensaje = `¿Deseas eliminar el registro de ${item.checador} del día ${dia} ${texto}?`;

      window
        .swal({
          title: mensaje,
          text: "Una vez eliminado no se puede recuperar",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarTurno(item.id);
          }
        });
    },
    importeChecador(checador) {
      let minutos = this.items.filter(x => x.checador == checador);
      return window._.sumBy(minutos, "importe");
    },
    recibir(item) {
      this.turno = Object.assign({}, item);
      this.turno.dinero_entregado = item.importe;
      this.pagar = true;
    },
    mandar() {
      window.axios
        .post(`minutos/recibir/${this.turno.id}`, this.turno)
        .then(resp => {
          this.SET_COLLECTION(resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.pagar = false;
        });
    },
    cantidadChecador(checador) {
      let minutos = this.items.filter(x => x.checador == checador);
      return minutos.length;
    }
  }
};
</script>
