import Usuarios from "./../views/users/index.vue";
import Formulario from "./../views/users/form.vue";

const routes = [
  {
    path: "/Usuarios",
    name: "users",
    component: Usuarios,
    meta: {
      name: "Control de Usuarios"
    }
  },
  {
    path: "/Usuarios/nuevo",
    name: "user.create",
    component: Formulario,
    meta: {
      name: "Usuario Nuevo"
    }
  },
  {
    path: "/Usuarios/editar/:id",
    name: "user.edit",
    component: Formulario,
    meta: {
      name: "Editando Usuario"
    }
  }
];
export default routes;
