<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn small color="success" @click="newResource">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1 mt-2"
        :items="items"
        :headers="headers"
        :items-per-page="items.length"
        hide-default-footer
        :loading="loader"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="getResource(item.id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Puntos",
  computed: {
    ...mapState({
      headers: state => state.Puntos.headers,
      items: state => state.Puntos.collection,
      loader: state => state.Puntos.loader
    })
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Puntos", ["getAll", "newResource", "getResource"])
  }
};
</script>
