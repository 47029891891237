<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagos del Mes {{ mes }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn small color="info" class="mt-5 ml-3" @click="getAll">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        dense
        class="elevation-1 mt-3"
        hide-default-footer
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | moneda }}
        </template>
        <template v-slot:[`item.normal`]="{ item }">
          {{ item.normal | moneda }}
        </template>
        <template v-slot:[`item.atrasado`]="{ item }">
          {{ item.atrasado | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Mes",
  computed: {
    ...mapState({
      mes: state => state.Tarjetas.mes.mes,
      items: state => state.Tarjetas.mes.semanas
    })
  },
  data: () => ({
    headers: [
      { text: "Tarjeta", value: "title" },
      { text: "Pago Normal", value: "normal", align: "end" },
      { text: "Pago Atrasado", value: "atrasado", align: "end" },
      { text: "Total", value: "total", align: "end" }
    ]
  }),
  methods: {
    ...mapActions("Tarjetas", ["getAll"])
  }
};
</script>
