var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[(_vm.boleto)?_c('v-card-text',[_c('v-row',{staticClass:"mt-3"},[_c('v-col',[_vm._v(" Fecha: "),_c('strong',[_vm._v(_vm._s(_vm._f("fechaLarga")(_vm.boleto.created_at)))])]),_c('v-col',[_vm._v("Unidad: "),_c('strong',[_vm._v(_vm._s(_vm.boleto.unidad2))])]),_c('v-col',[_vm._v(" Boletos: "),_c('strong',[_vm._v(_vm._s(_vm.boleto.boletos))])]),_c('v-col',[_vm._v(" Importe: "),_c('strong',[_vm._v(_vm._s(_vm._f("moneda")(_vm.boleto.importe)))])]),_c('v-col',[_vm._v(" Supervisor: "),_c('strong',[_vm._v(_vm._s(_vm.boleto.supervisor))])]),_c('v-col',[_vm._v(" Operador: "),_c('strong',[_vm._v(_vm._s(_vm.boleto.operador))])])],1)],1):_vm._e(),(_vm.recuperados)?_c('v-card-text',[_c('h4',[_vm._v("Dinero Recuperado "+_vm._s(_vm._f("moneda")(_vm.importe)))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.recuperados,"items-per-page":_vm.recuperados.length},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fechaLarga")(item.fecha))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"red","disabled":_vm.loading},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1):_vm._e(),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.regresar}},[_vm._v(" regresar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }