<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" xl="2">
          <v-text-field
            outlined
            dense
            label="Fecha"
            v-model="fecha"
            type="date"
          />
        </v-col>
        <v-col>
          <v-btn
            color="success"
            v-if="fecha"
            @click="buscar"
            :disabled="!porcentajes"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="porcentajes">
        <v-row>
          <v-col cols="12">
            <h2 class="mb-3">{{ dia }}</h2>
          </v-col>
          <v-col cols="12" md="6">
            <Porcentajes :datos="porcentajes" />
          </v-col>
          <v-col cols="12" md="6">
            <Checadas :datos="checadas" />
          </v-col>
        </v-row>
        <v-row class="my-4">
          <v-col cols="12" xl="4">
            <Manuales :checadas="manuales" />
          </v-col>
          <v-col cols="12" xl="8">
            <Vueltas :items="vueltas" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Incompletas :items="incompletas" />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import Porcentajes from "./stats/Porcentajes.vue";
import Checadas from "./stats/Checadas.vue";
import Manuales from "./stats/Manuales.vue";
import Vueltas from "./stats/Vueltas.vue";
import Incompletas from "./stats/Incompletas.vue";

export default {
  name: "Test",
  components: {
    Porcentajes,
    Checadas,
    Manuales,
    Vueltas,
    Incompletas
  },
  data: () => ({
    fecha: undefined,
    porcentajes: undefined,
    checadas: undefined,
    manuales: undefined,
    dia: undefined,
    vueltas: undefined,
    incompletas: undefined
  }),
  computed: {
    today() {
      return new Date().toISOString().split("T")[0];
    }
  },
  methods: {
    buscar() {
      this.porcentajes = this.checadas = this.manuales = this.dia = this.vueltas = this.incompletas = undefined;
      window.axios
        .get("stats", {
          params: {
            fecha: this.fecha
          }
        })
        .then(resp => {
          this.porcentajes = resp.data.porcentajes;
          this.checadas = resp.data.checadas;
          this.manuales = resp.data.manuales;
          this.dia = resp.data.fecha;
          this.vueltas = resp.data.vueltas;
          this.incompletas = resp.data.incompletas;
        })
        .catch(e => this.$store.dispatch("errores", e));
    }
  },
  watch: {
    fecha: function() {
      this.buscar();
    }
  }
};
</script>
