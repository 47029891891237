<template>
  <v-row>
    <v-col class="mt-5">
      Registros Encontrados: {{ paginado.itemsLength }}
    </v-col>
    <v-col cols="12" md="8">
      <v-pagination
        class="mt-3"
        v-model="paginado.page"
        :length="paginado.pageCount"
        total-visible="8"
      />
    </v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
export default {
  name: "Pagination",
  props: ["paginado"]
};
</script>
