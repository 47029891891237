<template>
  <v-card class="mb-3" v-if="checadas">
    <v-card-title primary-title> {{ total }} Checadas del día</v-card-title>
    <v-card-text>
      <pie-chart
        :chart-data="checadas"
        :options="options"
        :height="250"
      ></pie-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import PieChart from "./../PieChart.js";
export default {
  name: "Checadas",
  components: { PieChart },
  data: () => ({
    options: {
      maintainAspectRatio: false
    }
  }),
  computed: {
    ...mapState({
      dashboard: state => state.Auth.dashboard
    }),
    checadas() {
      return this.dashboard.checadas ? this.dashboard.checadas.checadas : null;
    },
    total() {
      return this.dashboard.checadas ? this.dashboard.checadas.total : null;
    }
  },
  mounted() {
    if (!this.dashboard) this.$store.dispatch("Auth/dashboard");
  }
};
</script>
