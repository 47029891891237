var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[(_vm.actual && !_vm.actual.importe)?_c('v-card-text',[_c('v-row',[(_vm.icoglo)?_c('v-col',[_vm._v(" Última Respuesta Tracker: "+_vm._s(_vm._f("segundos")(_vm.icoglo.hora))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.icoglo.resultados)+" resultados ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info","disabled":_vm.loading},on:{"click":_vm.getSalidas}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":"0","items-per-page":_vm.perPage,"item-class":_vm.row_classes2},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.hora_salida",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.hora_salida))+" ")]}},{key:"item.programada",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.programada))+" ")]}},{key:"item.real",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.real))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"secondary","disabled":_vm.loading},on:{"click":function($event){return _vm.detalleSalida(item.id)}}},[_c('v-icon',[_vm._v("mdi-check-box-multiple-outline")])],1)]}},{key:"item.ruta",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item)}},[_vm._v(" "+_vm._s(item.ruta)+" ")])]}}],null,true)})],1):_c('v-card-text',[_vm._v(" Solo puedes ver las llegadas si tienes un turno abierto ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }