/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "gastos";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async eliminarGasto({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
