<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="light-blue lighten-4">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4" class="mt-4">
          <v-text-field
            outlined
            dense
            type="date"
            label="Fecha"
            v-model="date"
          />
        </v-col>
        <v-col>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="buscar"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        mobile-breakpoint="0"
        dense
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        v-if="headers.length"
        :item-class="color"
      >
        <template v-slot:[`item.total`]="{ item }">
          {{ item.total | moneda }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "Semana",
  data: () => ({
    title: "Semana Actual",
    date: undefined,
    loading: false,
    headers: [],
    items: []
  }),
  methods: {
    buscar() {
      this.headers = [];
      this.items = [];
      window.axios.post("minutos/buscar", { date: this.date }).then(resp => {
        this.date = resp.data.fecha;
        this.headers = resp.data.headers;
        this.items = resp.data.items;
      });
    },
    color(item) {
      if (item.checador == "TOTAL") {
        return "green lighten-4";
      }
      if(item.checador =='EN LIQUIDACIONES'){
        return "blue lighten-4";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
td {
  font-size: 0.7em !important;
}
</style>
