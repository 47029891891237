<template>
  <v-card height="100%">
    <v-card-text>
      <v-form :disabled="loader" @submit.prevent="getData">
        <v-row class="mb-3">
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              label="Fecha Inicial"
              v-model="start"
              :error-messages="errors.start"
              hide-details="auto"
              type="date"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              dense
              outlined
              label="Fecha Final"
              v-model="end"
              :error-messages="errors.end"
              hide-details="auto"
              type="date"
            />
          </v-col>
          <v-col>
            <v-btn
              color="success"
              :disabled="loader"
              :loading="loader"
              type="submit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              color="error"
              v-if="items.length"
              @click="downloadPDF"
              class="ml-2"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar en Tabla"
              v-model="search"
              hide-details="auto"
              append-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        hide-default-footer
        mobile-breakpoint="0"
        class="elevation-1"
        :items-per-page="items.length"
        :search.sync="search"
        :loading="loader"
      >
        <template v-slot:[`item.imp_atrasadas`]="{ item }">
          {{ item.imp_atrasadas | moneda }}
        </template>
        <template v-slot:[`item.imp_normales`]="{ item }">
          {{ item.imp_normales | moneda }}
        </template>
        <template v-slot:[`item.imp_total`]="{ item }">
          {{ item.imp_total | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "ReporteTarjetas",
  data: () => ({
    search: null,
    start: null,
    end: null,
    items: [],
    headers: [
      { text: "Unidad", value: "unidad" },
      {
        text: "Tarjetas\nAtrasadas",
        value: "atrasadas",
        align: "end",
        width: "5"
      },
      {
        text: "Importe\nAtrasadas",
        value: "imp_atrasadas",
        align: "end",
        width: "5"
      },
      {
        text: "Tarjetas\nNormales",
        value: "normales",
        align: "end",
        width: "5"
      },
      {
        text: "Importe\nNormales",
        value: "imp_normales",
        align: "end",
        width: "5"
      },
      {
        text: "Total\nTarjetas",
        value: "total",
        align: "end",
        width: "5"
      },
      {
        text: "Importe\nTotal",
        value: "imp_total",
        align: "end",
        width: "5"
      }
    ]
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapActions(["errores"]),
    ...mapMutations(["setLoader", "setErrors"]),
    getData() {
      this.items = [];
      this.setLoader(true);
      this.setErrors({});
      window.axios
        .post("reporte-tarjetas", {
          start: this.start,
          end: this.end
        })
        .then(resp => {
          this.items = resp.data.items;
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    downloadPDF() {
      let titulo = `Tarjetas del ${this.$options.filters.date(
        this.start
      )} al ${this.$options.filters.date(this.end)}`;
      window
        .axios({
          url: "/reportes/pdtTarjetas", //your url
          method: "GET",
          responseType: "blob" // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${titulo}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>
