<template>
  <v-card height="100%">
    <v-expansion-panels multiple v-if="unidades">
      <Mes/>
      <Busqueda />
      <Pagados />
      <Cancelados />
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Busqueda from "./buscar";
import Pagados from "./pagados";
import Cancelados from "./cancelados";
import Mes from './mes'
import { mapActions, mapState } from "vuex";
export default {
  name: "Tarjetas",
  components: {  Busqueda, Pagados, Cancelados ,Mes},
  computed: {
    ...mapState({
      unidades: state => state.Tarjetas.unidades
    })
  },
  mounted() {
    if (!this.unidades) {
      this.getAll();
    }
  },
  methods: {
    ...mapActions("Tarjetas", ["getAll"])
  }
};
</script>
