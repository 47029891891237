<template>
  <v-card height="100%">
    <v-card-text v-if="historial">
      <v-expansion-panels multiple>
        <SalidasHoy v-if="historial.hoy.length" />
        <Salidas />
        <Tarjetas />
        <Concentrado />
        <Busqueda v-if="user.tipo == 'PATRON'" />
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">
        regresar a unidades
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import SalidasHoy from "./panelSalidasHoy";
import Salidas from "./panelSalidas";
import Tarjetas from "./panelTarjetas";
import Busqueda from "./PanelBusqueda";

import Concentrado from "./panelLiquidaciones";
export default {
  name: "Historial",
  components: { SalidasHoy, Salidas, Tarjetas, Concentrado, Busqueda },
  computed: {
    ...mapState({
      unidad: state => state.Unidades.resource,
      historial: state => state.Unidades.historial,
      user: state => state.Auth.user
    })
  },
  mounted() {
    if (!this.unidad) {
      let unidad = { id: this.$route.params.id };
      this.$store.dispatch("Unidades/historial", unidad);
    }
  },
  methods: {
    regresar() {
      this.$router.push({ name: "unidades" }).catch(() => {});
    }
  }
};
</script>
