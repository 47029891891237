<template>
  <v-card height="100%">
    <v-card-text v-if="salida">
      <p class="text-justify">
        Ruta: {{ salida.nombreRuta }} <br />
        Unidad: {{ salida.unidad }} <br />
        Operador:{{ salida.operador }} <br />
        <template v-if="modificarBoletos">
          <v-col cols="12" md="3">
            <v-text-field
              outlined
              dense
              type="number"
              label="Boleto Inicial"
              v-model.number="salida.boleto_inicial"
              class="mt-3"
              :hint="`Boleto Ingresado ${original.boleto_inicial}`"
            />
          </v-col>
        </template>
        <template v-else>
          Boleto Inicial: {{ salida.boleto_inicial | numero }} <br />
        </template>
        Fecha de Salida: {{ salida.hora_salida | date }} <br />
        Hora de Salida: {{ salida.hora_salida | hora }} <br />
        Checador: {{ salida.checador }}
      </p>
      <v-row v-if="userCan('crear-salida')">
        <v-col cols="12" class="mt-3">
          <v-textarea
            outlined
            label="Observaciones"
            v-model="salida.observaciones"
            rows="3"
          />
        </v-col>
        <v-col>
          <v-btn
            color="success"
            v-if="modificado"
            class="mb-3"
            @click="updateSalida"
          >
            actualizar
          </v-btn>
        </v-col>
      </v-row>
      <template v-else>
        <div v-if="salida.observaciones" class="mb-3">
          <h4>Observaciones</h4>
          {{ salida.observaciones }}
        </div>
      </template>

      <v-data-table
        dense
        mobile-breakpoint="0"
        hide-default-footer
        class="elevation-1"
        :items="items"
        :headers="headers"
        :search.sync="search"
        :item-class="row_classes"
      >
        <template v-slot:[`item.programado`]="{ item }">
          {{ item.programado | hora }}
        </template>
        <template v-slot:[`item.programada`]="{ item }">
          {{ item.programada | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          {{ item.real | hora }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loading"
            @click="getSalida(item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Details",
  data: () => ({
    headers: [
      { text: "Punto de checada", value: "punto" },
      { text: "Hora Programada", value: "programado" },
      { text: "Hora Real", value: "real" },
      { text: "Quemados", value: "quemados", align: "end" },
      { text: "Directos", value: "directos", align: "end" },
      { text: "Checador", value: "checador" }
    ],
    search: undefined,
    original: undefined
  }),
  computed: {
    ...mapState({
      salida: state => state.Salidas.resource,
      items: state => state.Salidas.checadas,
      user: state => state.Auth.user
    }),
    modificado() {
      return (
        JSON.stringify(this.salida) !== JSON.stringify(this.original) &&
        this.salida.observaciones != "" && this.salida.boleto_inicial
      );
    },
    modificarBoletos() {
      return this.user.tipo == "ADMINISTRADOR" || this.user.tipo == "MONITOR";
    }
  },
  mounted() {
    this.original = Object.assign({}, this.salida);
  },
  watch: {
    salida: function(val) {
      this.original = Object.assign({}, val);
    }
  },
  methods: {
    ...mapActions("Salidas", ["updateSalida"])
  }
};
</script>
