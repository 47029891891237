<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="red lighten-3">
      {{ new Date() | fechaLarga }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="items"
        :item-class="row_classes"
        hide-default-footer
      >
        <template v-slot:[`item.hora`]="{ item }">
          {{ item.hora | hora }}
        </template>
        <template v-slot:[`item.boleto`]="{ item }">
          {{ item.boleto | numero }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SalidasHoy",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Hora", value: "hora" },
      { text: "Operador", value: "operador" },
      { text: "Boleto", value: "boleto" },
      { text: "Quemados", value: "quemados", align: "end" },
      { text: "Directos", value: "directos", align: "end" },
      { text: "Observaciones", value: "observaciones" }
    ]
  }),
  computed: {
    ...mapState({
      loading: state => state.Unidades.loader,
      items: state => state.Unidades.historial.hoy
    })
  }
};
</script>
