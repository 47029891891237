<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            :disabled="loading"
            v-if="userCan('crear-gastos')"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        class="elevation-1"
        mobile-breakpoint="0"
        :items-per-page="perPage"
        :headers="headers"
        :items="items"
        :search.sync="search"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="red"
            v-if="item.eliminable"
            :disabled="loading"
            @click="eliminar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Nuevo Gasto</span>
        </v-card-title>
        <v-form @submit.prevent="guardar">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  type="date"
                  label="Fecha"
                  v-model="gasto.fecha"
                  :error-messages="errors.fecha"
                />
              </v-col>
              <v-col cols="12">
                <v-combobox
                  dense
                  outlined
                  small-chips
                  label="Tipo de Gasto"
                  :items="tipos"
                  v-model="gasto.tipo"
                  :error-messages="errors.tipo"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Concepto"
                  v-model="gasto.concepto"
                  :error-messages="errors.concepto"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  label="Importe"
                  step="0.01"
                  v-model.number="gasto.importe"
                  :error-messages="errors.importe"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="cancelar">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Gastos",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Tipo", value: "tipo" },
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Usuario", value: "usuario" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    perPage: 15,
    dialog: false,
    gasto: {}
  }),
  computed: {
    ...mapState({
      loading: state => state.Gastos.loader,
      items: state => state.Gastos.collection,
      errors: state => state.errors
    }),
    tipos() {
      if (!this.items) return [];
      return [...new Set(this.items.map(x => x.tipo))].sort();
    }
  },
  mounted() {
    if (!this.items) this.getAll();
  },
  methods: {
    nuevo() {
      this.SET_ERRORS([]);
      this.dialog = true;
      this.gasto = {};
    },
    ...mapActions("Gastos", ["getAll", "eliminarGasto"]),
    ...mapMutations("Gastos", ["SET_LOADER", "SET_COLLECTION"]),
    ...mapActions(["errores"]),
    ...mapMutations(["SET_ERRORS"]),
    cancelar() {
      this.dialog = false;
    },
    guardar() {
      this.SET_ERRORS([]);
      this.SET_LOADER(true);
      window.axios
        .post("gastos", this.gasto)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.SET_COLLECTION(resp.data.collection);
          this.cancelar();
        })
        .catch(e => this.errores(e))
        .finally(() => this.SET_LOADER(false));
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar el gasto ${item.concepto}?`,
        text = "Esta acción no puede deshacerse";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarGasto(item.id);
          }
        });
    }
  }
};
</script>
