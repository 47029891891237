<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="blue lighten-4">
      Busqueda
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <v-autocomplete
            outlined
            dense
            small-chips
            clearable
            label="Unidad"
            :items="unidades"
            item-value="id"
            item-text="unidad"
            v-model="unidad"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-btn color="success" @click="buscar(unidad)" :disabled="listo">
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-3" v-if="resultados">
        <v-col cols="12" md="6" class="mb-3">
          <v-card height="100%">
            <v-card-title primary-title>
              <v-row>
                <v-col cols="12" md="6">
                  {{ resultados.pendientes.length }} Tarjetas Pendientes
                  {{ new Date().getFullYear() }}
                  {{ importePendientes | moneda }}
                </v-col>

                <v-col cols="12" md="6">
                  {{ resultados.atrasadas.length }} Tarjetas Anteriores
                  {{ importeAtrasadas | moneda }}
                </v-col>
              </v-row>
            </v-card-title>
            <Pendientes
              v-on:listo="limpiar"
              v-on:prueba="anteriores"
              v-if="!atrasados"
            />
            <Anteriores v-else v-on:regresar="buscando" v-on:listo="limpiar" />
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="mb-3">
          <v-card height="100%">
            <v-card-title primary-title>
              Tarjetas Pagadas
            </v-card-title>
            <TarjetasPagadas />
          </v-card>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import TarjetasPagadas from "./tarjetasPagadas";
import Pendientes from "./tarjetasPendientes";
import Anteriores from "./anteriores";
export default {
  name: "Busqueda",
  components: { TarjetasPagadas, Pendientes, Anteriores },
  data: () => ({
    unidad: undefined,
    listo: true,
    atrasados: false
  }),
  computed: {
    ...mapState({
      unidades: state => state.Tarjetas.unidades,
      resultados: state => state.Tarjetas.resultados
    }),
    importeAtrasadas() {
      return window._.sumBy(this.resultados.atrasadas, "importe");
    },
    importePendientes() {
      return window._.sumBy(this.resultados.pendientes, "importe");
    }
  },
  watch: {
    unidad: function(val) {
      this.SET_RESULTADOS(undefined);
      this.listo = true;
      this.atrasados = false;
      if (val) {
        this.listo = false;
      }
    }
  },
  methods: {
    ...mapMutations("Tarjetas", ["SET_RESULTADOS"]),
    ...mapActions("Tarjetas", ["buscar"]),
    limpiar() {
      this.SET_RESULTADOS(undefined);
      this.unidad = undefined;
      this.listo = true;
    },
    buscando() {
      this.atrasados = false;
    },
    anteriores() {
      this.atrasados = true;
    }
  },
  beforeDestroy() {
    this.SET_RESULTADOS(undefined);
  }
};
</script>
