<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="red lighten-4">
      Cancelados
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            :disabled="loading"
            @click="tarjetasCanceladas"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.cancelacion`]="{ item }">
          {{ item.cancelacion | fechaLarga }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Cancelados",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Unidad", value: "unidad" },
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Fecha Cancelación", value: "cancelacion" },
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Tarjetas.canceladas,
      loading: state => state.Tarjetas.loader
    })
  },
  methods: {
    ...mapActions("Tarjetas", ["tarjetasCanceladas"])
  }
};
</script>
