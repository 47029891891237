<template>
  <NewSalida
    @cerrar="dialog = false"
    @actualizar="actualizarDatos"
    v-if="dialog"
  />
  <v-card height="100%" v-else>
    <v-card-text v-if="items">
      Fecha: {{ ultima.hora_salida | fechaLarga }} <br />
      Última Respuesta Tracker
      {{ icoglo | segundos }} con {{ resultados }} registros <br />
      {{ unidades.length }} Unidades Trabajaron
    </v-card-text>
    <v-card-text>
      <v-row
        v-if="
          user.email == 'developer@zamgod.mx' ||
            user.email == 'ricardo@alberca.com'
        "
      >
        <v-col>
          <v-select
            dense
            outlined
            clearable
            :items="rutas"
            v-model="ruta"
            label="Filtrar por Ruta"
          />
        </v-col>
        <v-col>
          <v-autocomplete
            dense
            outlined
            clearable
            :items="unidades"
            v-model="unidad"
            label="Filtrar por Unidad"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn
            small
            class="mt-5 ml-3"
            color="success"
            :disabled="loading"
            v-if="user.roles.includes('monitor')"
            @click="nueva"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items-per-page="perPage"
        :items="items"
        :headers="headers"
        :search.sync="search"
        :item-class="row_classes"
      >
        <template v-slot:[`item.hora_salida`]="{ item }">
          {{ item.hora_salida | hora }}
        </template>
        <template v-slot:[`item.programada`]="{ item }">
          {{ item.programada | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          {{ item.real | hora }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loading"
            @click="getSalida(item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="error"
            :disabled="loading"
            v-if="userCan('borrar-salida')"
            @click="borrar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import NewSalida from "./NewSalida.vue";
export default {
  name: "Salidas",
  components: { NewSalida },
  data: () => ({
    perPage: 15,
    dialog: false
  }),
  computed: {
    ...mapState({
      headers: state => state.Salidas.headers,
      collection: state => state.Salidas.collection,
      loading: state => state.Salidas.loader,
      date: state => state.Salidas.time,
      icoglo: state => state.Salidas.icoglo,
      resultados: state => state.Salidas.resultados,
      user: state => state.Auth.user,
      rutas: state => state.Salidas.rutas
    }),
    items() {
      let items = this.collection;
      if (this.ruta) items = items.filter(x => x.ruta == this.ruta);
      if (this.unidad) items = items.filter(x => x.unidad == this.unidad);
      return items;
    },
    unidades() {
      if (!this.collection) return [];
      return [...new Set(this.collection.map(x => x.unidad))].sort();
    },
    rutas() {
      if (!this.collection) return [];
      return [...new Set(this.collection.map(x => x.ruta))].sort();
    },
    ultima() {
      return this.collection ? this.collection[0] : {};
    },
    search: {
      // getter
      get: function() {
        return this.$store.state.Salidas.search;
      },
      // setter
      set: function(newValue) {
        this.$store.commit("Salidas/setSearch", newValue);
      }
    },
    unidad: {
      get: function() {
        return this.$store.state.Salidas.unidad;
      },
      set: function(val) {
        this.$store.commit("Salidas/setUnidad", val);
      }
    },
    ruta: {
      get: function() {
        return this.$store.state.Salidas.ruta;
      },
      set: function(val) {
        this.$store.commit("Salidas/setRuta", val);
      }
    }
  },
  mounted() {
    this.getAll();
    this.refreshIntervalId = setInterval(() => this.getAll(), 60000);
  },

  beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  },
  methods: {
    ...mapActions("Salidas", ["getAll", "getSalida", "eliminarSalida"]),
    ...mapMutations("Salidas", ["SET_COLLECTION"]),
    borrar(item) {
      let pregunta = `¿Deseas eliminar la salida de la unidad ${item.unidad}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarSalida(item.id);
          }
        });
    },
    nueva() {
      this.dialog = true;
      clearInterval(this.refreshIntervalId);
    },
    actualizarDatos() {
      this.dialog = false;
      this.getAll();
    }
  },
  watch: {
    dialog(val) {
      if (!val)
        this.refreshIntervalId = setInterval(() => this.getAll(), 60000);
    }
  }
};
</script>
