<template>
  <v-card height="100%">
    <v-card-text>
      <v-row class="mt-3">
        <v-col>
          <v-text-field
            dense
            outlined
            clearable
            label="Buscar"
            id="id"
            v-model="search"
          />
        </v-col>
        <v-col>
          <v-btn color="info" @click="getAll">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        :item-class="row_classes"
        mobile-breakpoint="0"
        class="elevation-1"
        :items-per-page="perPage"
        :search.sync="search"
        :loading="loader"
      >
        <template v-slot:[`item.hora_salida`]="{ item }">
          {{ item.hora_salida | hora }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Inspector",
  data: () => ({
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Salida", value: "hora_salida" },
      { text: "Unidad", value: "unidad" },
      { text: "Operador", value: "operador" },
      { text: "Observaciones", value: "observaciones" }
    ],
    items: [],
    perPage: 15,
    search: undefined,
    loader: false
  }),
  methods: {
    getAll() {
      this.loader = true;
      window.axios
        .get("salidas-inspector")
        .then(resp => {
          this.items = resp.data;
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => (this.loader = false));
    }
  },
  mounted() {
    this.getAll();
    this.refreshIntervalId = setInterval(() => this.getAll(), 60000);
  }
};
</script>
