/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "tarjetas";
const initialState = () => ({
  loader: false,
  unidades: undefined,
  resultados: undefined,
  collection: undefined,
  canceladas: undefined,
  mes: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_UNIDADES(state, payload) {
      state.unidades = payload;
    },
    SET_RESULTADOS(state, payload) {
      state.resultados = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_CANCELADAS(state, payload) {
      state.canceladas = payload;
    },
    SET_MES(state, payload) {
      state.mes = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_LOADER", true);
      commit("SET_RESULTADOS", undefined);
      commit("SET_MES", {});
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_UNIDADES", resp.data.unidades);
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_CANCELADAS", resp.data.canceladas);
          commit("SET_MES", resp.data.mes);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async buscar({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      commit("SET_RESULTADOS", undefined);
      await window.axios
        .post(`${url}/buscar/${id}`)
        .then(resp => {
          commit("SET_RESULTADOS", resp.data);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async ultimos({ commit, dispatch }) {
      commit("SET_LOADER", true);
      commit("SET_COLLECTION", undefined);
      await window.axios
        .post(`${url}/ultimos`)
        .then(resp => commit("SET_COLLECTION", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async tarjetasCanceladas({ commit, dispatch }) {
      commit("SET_LOADER", true);
      commit("SET_CANCELADAS", undefined);
      await window.axios
        .post(`${url}/tarjetasCanceladas`)
        .then(resp => commit("SET_CANCELADAS", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async eliminarTarjeta({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
