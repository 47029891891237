import Unidades from './../views/unidades/index.vue';
import Formulario from './../views/unidades/form.vue';
import Historial from './../views/unidades/historial.vue';
const routes = [
  {
    path: "/Unidades",
    name: "unidades",
    component: Unidades,
    meta: {
      name: "Control de Unidades"
    }
  },
  {
    path: "/Unidades/editar/:id",
    name: "unidad.edit",
    component: Formulario,
    meta: {
      name: "Editar Unidad"
    }
  },
  {
    path: "/Unidades/nueva",
    name: "unidad.create",
    component: Formulario,
    meta: {
      name: "Nueva Unidad"
    }
  },
  {
    path: "/Unidades/historial/:id",
    name: "unidad.historial",
    component: Historial,
    meta: {
      name: "Historial Unidad"
    }
  }
];
export default routes;
