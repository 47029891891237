<template>
  <v-card height="100%" v-if="resource">
    <v-form @submit.prevent="guardar">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              outlined
              dense
              type="date"
              label="Fecha del pago"
              v-model="resource.fecha"
              :max="hoy"
              :error-messages="errors.fecha"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Unidad"
              :items="unidades"
              item-value="unidad_id"
              item-text="unidad"
              v-model="unidad_id"
              :error-messages="errors.unidad_id"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
              dense
              outlined
              small-chips
              clearable
              label="Operador"
              :items="operadores"
              v-model="resource.operador"
              :error-messages="errors.operador"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-combobox
              dense
              outlined
              small-chips
              clearable
              label="Supervisor"
              :items="supervisores"
              v-model="resource.supervisor"
              :error-messages="errors.supervisor"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Boletos"
              type="number"
              step="1"
              v-model.number="resource.boletos"
              :error-messages="errors.boletos"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              dense
              outlined
              label="Importe"
              type="number"
              step="0.01"
              v-model.number="resource.importe"
              :error-messages="errors.importe"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loading">Guardar</v-btn>
        <v-btn color="error" @click="cancelar">cancelar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Nuevo",
  data: () => ({
    operadores: [],
    unidad_id: undefined
  }),
  computed: {
    ...mapState({
      errors: state => state.errors,
      loading: state => state.Boletos.loader,
      resource: state => state.Boletos.resource,
      unidades: state => state.Boletos.unidades,
      supervisores: state => state.Boletos.supervisores
    }),
    hoy() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1; //January is 0!
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    }
  },
  mounted() {
    if (!this.resource) this.cancelar();
  },
  methods: {
    cancelar() {
      this.$router.push({ name: "boletos" }).catch(() => {});
    },
    ...mapActions("Boletos", ["guardar"])
  },
  watch: {
    unidad_id: function(val) {
      this.resource.unidad_id = null;
      this.resource.operador = null;
      this.operadores = [];
      if (val) {
        this.resource.unidad_id = val;
        let seleccionada = this.unidades.find(x => x.unidad_id == val);
        console.log(seleccionada);
        this.operadores = seleccionada.operadores;
      }
    }
  }
};
</script>
