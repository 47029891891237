<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row v-if="user.email == 'developer@zamgod.mx'">
        <v-col cols="12" xl="4">
          <v-select
            dense
            outlined
            clearable
            label="Filtrar por Rol"
            v-model="rol"
            :items="roles"
            item-text="title"
            item-value="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            v-if="userCan('create-user')"
            :disabled="loading"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :item-class="row_classes"
        hide-default-footer
      >
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="item.deleted_at">
            <v-btn
              icon
              color="success"
              v-if="item.actions.restore"
              :disabled="loading"
              @click="restaurar(item)"
            >
              <v-icon>mdi-delete-restore</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              icon
              color="success"
              v-if="item.actions.edit"
              :disabled="loading"
              @click="editUser(item.id)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              v-if="item.actions.delete"
              :disabled="loading"
              @click="eliminar(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              color="secondary"
              v-if="
                user.email == 'developer@zamgod.mx' ||
                  user.email == 'ricardo@alberca.com'
              "
              @click="tokenById(item.id)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:[`item.last_login`]="{ item }">
          {{ item.last_login | dateTime }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Users",
  data: () => ({
    search: undefined,
    perPage: 15,
    rol: undefined
  }),
  computed: {
    ...mapState({
      headers: state => state.Users.headers,
      collection: state => state.Users.collection,
      loading: state => state.Users.loader,
      user: state => state.Auth.user,
      roles: state => state.Users.roles
    }),
    items() {
      return this.rol
        ? this.collection.filter(x => x.roles == this.rol)
        : this.collection;
    },
    itemsPerPage() {
      return this.items ? this.items.length : 20;
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Users", [
      "getAll",
      "disableUser",
      "restoreUser",
      "nuevo",
      "editUser",
      "abilities"
    ]),
    ...mapActions("Auth", ["tokenById"]),
    eliminar(item) {
      let pregunta = `¿Deseas deshabilitar a ${item.nombre}?`,
        text = "El usuario no podrá usar el sistema";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.disableUser(item.id);
          }
        });
    },
    restaurar(item) {
      let pregunta = `¿Deseas habilitar a ${item.nombre}?`,
        text = "El usuario  podrá usar el sistema";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.restoreUser(item.id);
          }
        });
    }
  }
};
</script>
