var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"green lighten-4"}},[_vm._v(" Pagados (Últimos 6 meses) ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info","disabled":_vm.loading},on:{"click":_vm.getPagados}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.fecha_entrega",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fechaLarga")(item.fecha_entrega))+" ")]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fechaLarga")(item.fecha))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.importe))+" ")]}},{key:"item.dinero_entregado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.dinero_entregado))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userCan('editar-minutos'))?_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loading},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.editando),callback:function ($$v) {_vm.editando=$$v},expression:"editando"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Editar ")])]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.actualizar(_vm.turno)}}},[_c('v-card-text',[_vm._v(" Fecha: "+_vm._s(_vm._f("fechaLarga")(_vm.turno.fecha))+" "),_c('br'),_vm._v(" Checador: "+_vm._s(_vm.turno.checador)+" "),_c('br'),_vm._v(" Importe Reportado: "+_vm._s(_vm._f("moneda")(_vm.turno.importe))+" "),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","step":"0.01","label":"Dinero Entregado"},model:{value:(_vm.turno.dinero_entregado),callback:function ($$v) {_vm.$set(_vm.turno, "dinero_entregado", _vm._n($$v))},expression:"turno.dinero_entregado"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Observaciones"},model:{value:(_vm.turno.observaciones),callback:function ($$v) {_vm.$set(_vm.turno, "observaciones", $$v)},expression:"turno.observaciones"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"type":"submit"}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loading},on:{"click":_vm.cancelar}},[_vm._v(" cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }