<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Fecha Inicial"
          type="date"
          v-model="inicio"
          :error-messages="errors.inicio"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          dense
          outlined
          label="Fecha Final"
          type="date"
          v-model="fin"
          :error-messages="errors.fin"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-btn color="success" :disabled="disabled" @click="buscar">
          <v-icon>mdi-card-search</v-icon>
        </v-btn>
        <v-btn color="error" class="ml-3" v-if="imprimible" @click="downloadPDF">
          <v-icon>mdi-file-pdf</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Nota: las semanas comienzan los dias lunes
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <Tarjetas />
      </v-col>
      <v-col cols="12" md="6">
        <Minutos />
      </v-col>
      <v-col cols="12" md="6">
        <Gastos />
      </v-col>
      <v-col cols="12" md="6">
        <Boletos />
      </v-col>
    </v-row>
    <v-row class="mt-4" v-if="ingresos > 0">
      <v-col>
        <v-card>
          <v-card-title primary-title>
            Resumen
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col> Ingresos: {{ ingresos | moneda }} </v-col>
              <v-col> Egresos: {{ egresos | moneda }} </v-col>
              <v-col> Total: {{ (ingresos - egresos) | moneda }} </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Tarjetas from "./cardTarjetas";
import Minutos from "./cardMinutos";
import Gastos from "./cardGastos";
import Boletos from "./cardBoletos";
export default {
  components: { Tarjetas, Minutos, Gastos, Boletos },
  name: "Financiero",
  data: () => ({
    inicio: undefined,
    fin: undefined
  }),
  computed: {
    egresos() {
      if (!this.resultados.boletos) return 0;
      let gastos = this.resultados.gastos,
        boletos = this.resultados.boletos.filter(x => x.tipo !== "Recuperado"),
        importeGastos = window._.sumBy(gastos, "importe"),
        importeBoletos = window._.sumBy(boletos, "importe");
      return importeBoletos + importeGastos;
    },
    disabled() {
      return this.inicio && this.fin ? false : true;
    },
    ...mapState({
      errors: state => state.errors,
      resultados: state => state.Reportes.resultados
    }),
    imprimible() {
      return this.resultados.minutos ? true : false;
    },
    ingresos() {
      if (!this.resultados.boletos) return 0;
      let minutos = this.resultados.minutos,
        tarjetas = this.resultados.tarjetas,
        boletos = this.resultados.boletos.filter(x => x.tipo == "Recuperado"),
        importeMinutos = window._.sumBy(minutos, "importe"),
        importeTarjetas = window._.sumBy(tarjetas, "importe"),
        importeBoletos = window._.sumBy(boletos, "importe");

      return importeBoletos + importeMinutos + importeTarjetas;
    }
  },
  mounted() {
    this.$store.commit("SET_ERRORS", {});
  },
  beforeDestroy() {
    this.$store.commit("Reportes/SET_RESULTADOS", {});
  },
  watch: {
    resultados: function(val) {
      this.inicio = val.inicio;
      this.fin = val.fin;
    }
  },
  methods: {
    ...mapActions("Reportes", ["getFinanciero"]),
    buscar() {
      let data = {
        inicio: this.inicio,
        fin: this.fin
      };
      this.getFinanciero(data);
    },
    downloadPDF() {
      window
        .axios({
          url: "/reportes/downloadPdfFinanciero", //your url
          method: "GET",
          responseType: "blob" // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Reporte Financiero.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>
