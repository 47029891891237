<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="blue lighten-3">
      Checadas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1 mt-3"
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" :disabled="loading" @click="editar(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" :disabled="loading" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        small
        color="success"
        class="mt-5"
        :disabled="loading"
        @click="nueva"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-expansion-panel-content>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ modalTitle }}</span>
          </v-card-title>
          <v-form @submit.prevent="enviar">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    outlined
                    dense
                    small-chips
                    v-model="checada.punto_id"
                    label="Punto de Checada"
                    :items="puntos"
                    :error-messages="errors.punto_id"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Tiempo desde la base (minutos)"
                    v-model.number="checada.tiempo"
                    :error-messages="errors.tiempo"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                    outlined
                    dense
                    small-chips
                    v-model="checada.directo"
                    label="Minutos Directos"
                    :items="directos"
                    :error-messages="errors.directo"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelar" :disabled="loading">
                Cancelar
              </v-btn>
              <v-btn color="success" type="submit" :disabled="loading">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Checadas",
  data: () => ({
    headers: [
      { text: "Punto", value: "nombre" },
      { text: "Posición", value: "posicion" },
      { text: "Tiempo", value: "tiempo" },
      { text: "Minutos Directos", value: "directo" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    checada: {},
    dialog: false,
    directos: [
      { value: null, text: "NO" },
      { value: "SI", text: "SI" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Rutas.puntosRuta,
      loading: state => state.Rutas.loader,
      puntos: state => state.Rutas.puntos,
      ruta: state => state.Rutas.resource,
      errors: state => state.errors
    }),
    modalTitle() {
      return this.checada.id ? "Editando Checada" : "Nueva Checada";
    }
  },
  methods: {
    ...mapActions(["errores"]),
    ...mapActions("Rutas", ["sendChecada"]),
    ...mapMutations(["SET_ERRORS"]),
    ...mapMutations("Rutas", ["SET_LOADER", "SET_PUNTOS_RUTA"]),
    nueva() {
      this.SET_ERRORS([]);
      this.checada = { ruta_id: this.ruta.id };
      this.dialog = true;
    },
    editar(item) {
      this.SET_ERRORS([]);
      this.checada = Object.assign({}, item);
      this.dialog = true;
    },
    enviar() {
      this.SET_ERRORS([]);
      this.SET_LOADER(true);
      if (!this.checada.id) {
        window.axios
          .post(`/addPuntoChecada/${this.ruta.id}`, this.checada)
          .then(resp => {
            this.SET_PUNTOS_RUTA(resp.data.puntosRuta);
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            this.cancelar();
          })
          .catch(e => {
            this.errores(e);
          })
          .finally(() => this.SET_LOADER(false));
      } else {
        window.axios
          .put(`/updateChecada/${this.checada.id}`, this.checada)
          .then(resp => {
            this.SET_PUNTOS_RUTA(resp.data.puntosRuta);
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            this.cancelar();
          })
          .catch(e => {
            this.errores(e);
          })
          .finally(() => this.SET_LOADER(false));
      }
    },
    cancelar() {
      this.SET_LOADER(false);
      this.SET_ERRORS([]);
      this.dialog = false;
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar la checada ${item.nombre} de la ruta?`;
      window
        .swal({
          title: pregunta,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.SET_LOADER(true);
            window.axios
              .delete(`/updateChecada/${item.id}`)
              .then(resp => {
                this.SET_PUNTOS_RUTA(resp.data.puntosRuta);
                window.swal(resp.data.message, {
                  icon: "success",
                  timer: 1000
                });
                this.cancelar();
              })
              .catch(e => {
                this.errores(e);
              })
              .finally(() => this.SET_LOADER(false));
          }
        });
    }
  }
};
</script>
