import Monitor from "./../views/monitor/index.vue";
import Detalle from './../views/monitor/detalle.vue';
import Solicitar from './../views/monitor/solicitar.vue';
const routes = [
  {
    path: "/Monitor",
    name: "monitor",
    component: Monitor,
    meta: {
      name: "Monitor"
    }
  },
  {
    path: "/Monitor/detalle/:id",
    name: "monitor.detail",
    component: Detalle,
    meta: {
      name: "Detalle de Salida"
    }
  },
  {
    path: "/Monitor/solicitar",
    name: "monitor.solicitar",
    component: Solicitar,
    meta: {
      name: "Solicitar Datos a iCoglo"
    }
  }
];
export default routes;
