<template>
  <v-card height="100%">
    <v-form @submit.prevent="sendResource" :disabled="loader">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              dense
              outlined
              label="Nombre"
              v-model="resource.nombre"
              :error-messages="errors.nombre"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              dense
              outlined
              label="Ubicación"
              v-model="resource.ubicacion"
              :error-messages="errors.ubicacion"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" type="submit" :disabled="loader">{{
          textBtn
        }}</v-btn>
        <v-btn color="error" @click="$router.go(-1)" :disabled="loader"
          >cancelar</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Formulario",
  computed: {
    textBtn() {
      return this.$route.params.id ? "actualizar" : "guardar";
    },
    ...mapState({
      loader: state => state.Puntos.loader,
      resource: state => state.Puntos.resource,
      errors: state => state.errors
    })
  },
  beforeMount() {
    this.$store.commit("SET_ERRORS", {});
    if (!this.resource) {
      if (this.$route.params.id) {
        this.getResource(this.$route.params.id);
      } else {
        this.newResource();
      }
    }
  },
  methods: {
    ...mapActions("Puntos", ["newResource", "getResource", "sendResource"])
  }
};
</script>
