<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="green lighten-3">
      Salidas Últimos 3 meses
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-4">
        <v-col cols="12" md="6">
          <v-autocomplete dense outlined clearable label="Filtrar por Operador" v-model="operador" :items="operadores"/>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="filtrados"
        :item-class="row_classes"
      >
        <template v-slot:[`item.hora`]="{ item }">
          {{ item.hora | hora }}
        </template>
        <template v-slot:[`item.boleto`]="{ item }">
          {{ item.boleto | numero }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Salidas",
  data: () => ({
    search: undefined,
    operador: undefined,
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Fecha", value: "fecha" },
      { text: "Hora", value: "hora" },
      { text: "Operador", value: "operador" },
      { text: "Boleto", value: "boleto" },
      { text: "Quemados", value: "quemados" },
      { text: "Directos", value: "directos" },
      { text: "Observaciones", value: "observaciones" }
    ]
  }),
  computed: {
    ...mapState({
      loading: state => state.Unidades.loader,
      items: state => state.Unidades.historial.salidas
    }),
    operadores() {
      return [...new Set(this.items.map(x => x.operador))].sort();
    },
    filtrados() {
      return this.operador
        ? this.items.filter(x => x.operador == this.operador)
        : this.items;
    }
  }
};
</script>
