<template>
  <v-card height="100%">
    <v-card-text v-if="salida">
      <p class="text-justify">
        Ruta: {{ salida.nombreRuta }} <br />
        Unidad: {{ salida.unidad }} <br />
        Operador:{{ salida.operador }} <br />
        Boleto Inicial: {{ salida.boleto_inicial | numero }} <br />
        Fecha de Salida: {{ salida.hora_salida | date }} <br />
        Hora de Salida: {{ salida.hora_salida | hora }} <br />
        Checador: {{ salida.checador }}
      </p>
      <v-row>
        <v-col cols="12" class="mt-3">
          <v-textarea
            outlined
            label="Observaciones"
            v-model="salida.observaciones"
            rows="3"
          />
        </v-col>
        <v-col>
          <v-btn
            color="success"
            v-if="modificado"
            class="mb-3"
            :disabled="loading"
            @click="setObservaciones"
          >
            guardar observaciones
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items="items"
        :headers="headers"
        :search.sync="search"
        :item-class="row_classes"
      >
        <template v-slot:[`item.programado`]="{ item }">
          {{ item.programado | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          <template v-if="isMonitor">
            <template v-if="item.real">
              <template v-if="userCan('modificar-checada')">
                <v-row>
                  <v-col>
                    <v-text-field dense v-model="item.modificado" type="time" />
                  </v-col>
                  <v-col>
                    <v-btn
                      small
                      color="warning"
                      class="mt-2"
                      :disabled="loading"
                      @click="modificarChecada(item.id)"
                      v-if="item.modificado !== item.real2"
                    >
                      <v-icon small>mdi-content-save</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                {{ item.real | hora }}
              </template>
            </template>
            <template v-else>
              <v-row>
                <v-col>
                  <v-text-field dense v-model="item.prueba" type="time" />
                </v-col>
                <v-col>
                  <v-btn
                    small
                    color="success"
                    class="mt-2"
                    :disabled="loading"
                    @click="updateChecada(item.id)"
                  >
                    <v-icon small>mdi-content-save</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </template>
          <template v-else>
            {{ item.real | hora }}
          </template>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Salida",
  data: () => ({
    headers: [
      { text: "Punto de checada", value: "punto" },
      { text: "Hora Programada", value: "programado" },
      { text: "Hora Real", value: "real" },
      { text: "Checador", value: "checador" }
    ],
    search: undefined,
    original: undefined,
    time: null,
    menu2: false
  }),
  computed: {
    ...mapState({
      salida: state => state.Monitor.resource,
      items: state => state.Monitor.checadas,
      loading: state => state.Monitor.loader,
      user: state => state.Auth.user
    }),
    modificado() {
      return (
        JSON.stringify(this.salida) !== JSON.stringify(this.original) &&
        this.salida.observaciones != ""
      );
    },
    isMonitor() {
      return this.user.tipo == "MONITOR" || this.user.tipo == "ADMINISTRADOR";
    }
  },
  mounted() {
    this.original = Object.assign({}, this.salida);
  },
  watch: {
    salida: function(val) {
      this.original = Object.assign({}, val);
    }
  },
  methods: {
    ...mapActions("Monitor", [
      "updateChecada",
      "setObservaciones",
      "modificarChecada"
    ])
  }
};
</script>
