var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"success","disabled":_vm.loading},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info","disabled":_vm.loading},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha")(item.fecha))+" ")]}},{key:"item.patron",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.patron))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("folio")(item.id))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.print(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"icon":"","color":"red","disabled":_vm.loading},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }