<template>
  <v-card height="100%">
    <v-card-title primary-title>
      Nueva Salida
    </v-card-title>
    <v-form :disabled="loader" @submit.prevent="enviar">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="auto">
            <v-autocomplete
              :items="rutas"
              dense
              outlined
              clearable
              label="Ruta"
              hide-details="auto"
              v-model="resource.ruta_id"
              :error-messages="errors.ruta_id"
              item-text="nombre"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-autocomplete
              :items="unidades"
              dense
              outlined
              clearable
              label="Unidad"
              hide-details="auto"
              v-model="resource.unidad_id"
              :error-messages="errors.unidad_id"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              outlined
              dense
              type="time"
              label="Hora de Salida"
              v-model="resource.hora_salida"
              :error-messages="errors.hora_salida"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-combobox
              :items="operadores"
              outlined
              dense
              label="Operador"
              v-model="resource.operador"
              :error-messages="errors.operador"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="auto">
            <v-text-field
              outlined
              dense
              type="number"
              label="Boleto Inicial"
              v-model="resource.boleto_inicial"
              :error-messages="errors.boleto_inicial"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          :disabled="loader"
          :loading="loader"
          type="submit"
        >
          guardar
        </v-btn>
        <v-btn
          color="error"
          @click="$emit('cerrar')"
          :disabled="loader"
          :loading="loader"
        >
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "NewSalida",
  emits: ["actualizar", "cerrar"],
  data: () => ({
    resource: { unidad_id: null }
  }),
  computed: {
    ...mapState({
      rutas: state => state.Salidas.rutas,
      unidades: state => state.Salidas.unidades,
      loader: state => state.loader,
      errors: state => state.errors
    }),
    unidad() {
      return this.unidades.find(x => x.value == this.resource.unidad_id);
    },
    operadores() {
      return this.unidad ? this.unidad.operadores : [];
    }
  },
  methods: {
    ...mapActions(["errores"]),
    ...mapMutations(["setErrors", "setLoader"]),
    ...mapMutations("Salidas", ["setUnidades", "setRutas"]),
    enviar() {
      this.setErrors();
      this.setLoader();
      window.axios
        .post("salidas", this.resource)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.$emit("actualizar");
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    }
  },
  beforeMount() {
    this.setLoader();
    window.axios
      .get("salidas/create")
      .then(resp => {
        this.setRutas(resp.data.rutas);
        this.setUnidades(resp.data.unidades);
      })
      .catch(e => this.errores(e))
      .finally(() => this.setLoader(false));
  },
  mounted() {
    this.setErrors();
  },
  watch: {}
};
</script>
