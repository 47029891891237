<!-- eslint-disable -->
<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height fluid>
        <v-row>
          <v-col cols="12" md="6" offset-md="3">
            <v-card>
              <v-card-title>Ingresa tus Credenciales</v-card-title>
              <v-form @submit.prevent="login">
                <v-card-text>
                  <v-text-field
                    outlined
                    dense
                    autocomplete="email"
                    label="Correo"
                    prepend-icon="mdi-email"
                    type="email"
                    id="email"
                    v-model="user.email"
                    :error-messages="errors.email"
                  />
                  <v-text-field
                    outlined
                    dense
                    autocomplete="current-password"
                    id="password"
                    label="Contraseña"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="user.password"
                    :error-messages="errors.password"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" type="submit">Ingresar</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      errors: state => state.errors,
      user: state => state.Auth.user
    })
  },
  methods: {
    ...mapActions("Auth", ["login"])
  }
};
</script>
