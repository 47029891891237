<template>
  <v-card class="ma-1" height="98%">
    <v-card-title primary-title>
      MINUTOS
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="items">
      Importe Total: {{ total | moneda }}
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Minutos",
  data: () => ({
    headers: [
      { text: "Semana", value: "semana" },
      { text: "Importe", value: "importe", align: "end" }
    ]
  }),
  computed:{
    ...mapState({
      items: state => state.Reportes.resultados.minutos
    }),
    total() {
      return window._.sumBy(this.items, "importe");
    }
  }
};
</script>
