<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            outlined
            dense
            small-chips
            clearable
            :items="bases"
            label="Seleccionar Base"
            v-model="seleccionada"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SetBase",
  data: () => ({
    seleccionada: undefined
  }),
  computed: {
    ...mapState({
      bases: state => state.Checador.bases,
      base: state => state.Checador.base
    })
  },
  mounted() {
   this.getBases();
    this.seleccionada = this.base;
  },
  watch: {
    seleccionada: function(val) {
      if (val !== this.base) {
        this.SET_BASE(val);
      }
    }
  },
  methods: {
    ...mapActions("Checador", ["getBases"]),
    ...mapMutations("Checador", ["SET_BASE"])
  }
};
</script>
