<template>
  <v-card class="ma-1" height="98%">
    <v-card-title primary-title>
      TARJETAS
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.tarjetas`]="{ item }">
          {{ item.tarjetas | moneda }}
        </template>
        <template v-slot:[`item.atrasado`]="{ item }">
          {{ item.atrasado | moneda }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="items">
      Importe Total: {{ total | moneda }}
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Tarjetas",
  data: () => ({
    headers: [
      { text: "Semana", value: "semana" },
      { text: "Tarjetas", value: "tarjetas", align: "end" },
      { text: "Atrasado", value: "atrasado", align: "end" },
      { text: "Total", value: "importe", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Reportes.resultados.tarjetas
    }),
    total() {
      return window._.sumBy(this.items, "importe");
    }
  }
};
</script>
