import logout from "./../components/base/logout.vue";
import EditMe from "./../views/editMe.vue";
import Inicio from "./../views/Home.vue";
import Minutos from './../views/minutos/index.vue';
import Notifications from "./../views/Notifications.vue";
import Estadisticas from './../views/Estadisticas.vue';
const routes = [
  {
    path: "/",
    component: Inicio,
    meta: {
      name: "Inicio"
    }
  },
  {
    path: "/Inicio",
    name: "home",
    component: Inicio,
    meta: {
      name: "Inicio"
    }
  },
  {
    path: "/Salir",
    name: "logout",
    component: logout
  },
  {
    path: "/Editar-mis-datos",
    name: "editMe",
    component: EditMe,
    meta: {
      name: "Editar Mis Datos"
    }
  },
  {
    path: "/Minutos",
    name: "minutos",
    component: Minutos,
    meta: {
      name: "Control de Minutos"
    }
  },
  {
    path: "/Estadisticas",
    name: "estadisticas",
    component: Estadisticas,
    meta: {
      name: "Estadísticas"
    }
  }
];
export default routes;