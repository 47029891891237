import Salidas from "./../views/salidas/index.vue";
import Details from "./../views/salidas/detalle.vue";
import Inspector from "@/views/salidas/Inspector.vue";
const routes = [
  {
    path: "/Salidas",
    name: "salidas",
    component: Salidas,
    meta: {
      name: "Control de Salidas"
    }
  },
  {
    path: "/Salidas/detalle/:id",
    name: "salida.details",
    component: Details,
    meta: {
      name: "Detalle de Salida"
    }
  },
  {
    path: "/Salidas-Unidades",
    name: "salidas.inspector",
    component: Inspector,
    meta: {
      name: "Salidas Últimas 3 horas"
    }
  }
];
export default routes;
