<template>
  <v-card class="ma-1" height="98%">
    <v-card-title primary-title>
      BOLETOS
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        hide-default-footer
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="items">
      Importe Total: {{ total | moneda }}
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Boletos",
  data: () => ({
    headers: [
      { text: "Tipo", value: "tipo" },
      { text: "Importe", value: "importe", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      items: state => state.Reportes.resultados.boletos
    }),
    total() {
      let sumar = this.items.filter(x => x.tipo == "Recuperado"),
        resta = this.items.filter(x => x.tipo !== "Recuperado"),
        impSum = window._.sumBy(sumar, "importe"),
        impRes = window._.sumBy(resta, "importe");
      return impSum - impRes;
    }
  }
};
</script>
