<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            clearable
            dense
            outlined
            small-chips
            label="Seleccione el tipo de reporte"
            :items="tipos"
            v-model="seleccionado"
          />
        </v-col>
      </v-row>
      <Financiero v-if="seleccionado == 'Financiero'" />
      <Actividades v-if="seleccionado == 'Actividades'" />
      <Fechas v-if="seleccionado == 'Fechas Unidad'" />
      <HojaTrabajo v-if="seleccionado == 'Hoja de Trabajo'" />
    </v-card-text>
  </v-card>
</template>

<script>
import Financiero from "./financiero";
import Actividades from "./../Actividades.vue";
import Fechas from "./Fechas.vue";
import HojaTrabajo from "./HojaTrabajo.vue";
export default {
  name: "Reportes",
  components: { Financiero, Actividades, Fechas, HojaTrabajo },
  data: () => ({
    tipos: ["Actividades", "Financiero", "Fechas Unidad", "Hoja de Trabajo"],
    seleccionado: undefined
  })
};
</script>
