/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "minutos";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  pagados: undefined,
  editando: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_PAGADOS(state, payload) {
      state.pagados = payload;
    },
    SET_EDITANDO(state, payload) {
      state.editando = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(commit("SET_LOADER", false));
    },
    async getPagados({ commit, dispatch }) {
      commit("SET_PAGADOS", []);
      commit("SET_LOADER", true);
      await window.axios
        .get(`${url}/pagados`)
        .then(resp => commit("SET_PAGADOS", resp.data.collection))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(commit("SET_LOADER", false));
    },
    async eliminarTurno({ commit, dispatch }, id) {
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(commit("SET_LOADER", false));
    },
    async actualizar({ commit, dispatch }, item) {
      commit("SET_LOADER", true);
      await window.axios
        .put(`${url}/actualizar/${item.id}`, item)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_PAGADOS", resp.data.collection);
          commit("SET_EDITANDO", undefined);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => {
          commit("SET_LOADER", false);
        });
    }
  }
};
