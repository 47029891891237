<template>
  <v-card height="100%" v-if="user.id">
    <v-card-text>
      Bienvenido {{ user.full_name }}
      <v-row
        v-if="
          user.email == 'developer@zamgod.mx' ||
            user.email == 'ricardo@alberca.com'
        "
      >
        <v-col cols="12" xl="2">
          <v-checkbox class="mr-5" v-model="manual" label="Checadas Manuales" />
        </v-col>
        <v-col cols="12" xl="2">
          <v-checkbox class="mr-5" v-model="graficos" label="Gráficos" />
        </v-col>
        <v-col v-if="notifications.length" cols="12" xl="2">
          <v-checkbox
            class="mr-5"
            v-model="notificaciones"
            label="Notificaciones"
          />
        </v-col>
        <v-col class="mt-4">
          <strong>Hora Actualización: {{ hora | dateTime }}</strong>
        </v-col>
      </v-row>
    </v-card-text>

    <Notifications v-if="notificaciones" />
    <v-card-text
      v-if="
        user.email == 'developer@zamgod.mx' ||
          user.email == 'ricardo@alberca.com'
      "
    >
      <v-row>
        <v-col cols="12" md="6" xl="12" v-if="manual">
          <Manuales />
        </v-col>
        <template v-if="graficos">
          <v-col cols="12" md="6" xl="3">
            <Checadas />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <Porcentaje />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <Salidas />
          </v-col>
          <v-col cols="12" md="6" xl="3">
            <Unidades />
          </v-col>

          <v-col cols="12" v-if="dashboard.vueltas">
            <Vueltas :items="vueltas" :options="options" />
          </v-col>
        </template>
      </v-row>
    </v-card-text>
    <v-card-actions
      v-if="
        user.email == 'developer@zamgod.mx' ||
          user.email == 'ricardo@alberca.com'
      "
    >
      <v-btn color="primary" @click="actualizar" :disabled="loader">
        actualizar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Notifications from "./Notifications";
import Checadas from "./dashboard/Checadas";
import Manuales from "./dashboard/Manuales";
import Salidas from "./dashboard/Salidas";
import Unidades from "./dashboard/Unidades";
import Vueltas from "./stats/Vueltas.vue";
import Porcentaje from "./dashboard/Porcentaje";
export default {
  name: "Dashboard",
  components: {
    Notifications,
    Checadas,
    Manuales,
    Salidas,
    Unidades,
    Vueltas,
    Porcentaje
  },
  computed: {
    ...mapState({
      user: state => state.Auth.user,
      turnos: state => state.Checador.turnos,
      loader: state => state.Auth.loader,
      dashboard: state => state.Auth.dashboard,
      notifications: state => state.Auth.notifications
    }),
    vueltas() {
      return this.dashboard.vueltas;
    },
    hora() {
      return this.dashboard.hora;
    }
  },
  mounted() {
    this.$store.dispatch("Auth/getNotifications");
    if (this.user.tipo == "CHECADOR") {
      if (!this.turnos) this.getTurnos();
    }
    this.$store.dispatch("Auth/dashboard");
    this.refreshIntervalId = setInterval(() => this.actualizar(), 300000);
    this.actualizar();
  },
  methods: {
    ...mapActions("Checador", ["getTurnos"]),
    actualizar() {
      this.$store.dispatch("Auth/getNotifications");
      this.$store.dispatch("Auth/dashboard");
    }
  },
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 1
            }
          }
        ]
      },
      maintainAspectRatio: false
    },
    graficos: true,
    manual: false,
    notificaciones: false
  }),
  beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  }
};
</script>
