<template>
  <div>
    <v-form :disabled="loader" @submit.prevent="buscar">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            dense
            outlined
            label="Fecha Inicial"
            type="date"
            v-model="inicio"
            :error-messages="errors.inicio"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            dense
            outlined
            label="Fecha Final"
            type="date"
            v-model="fin"
            :error-messages="errors.fin"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            dense
            outlined
            label="Unidad"
            v-model="unidad"
            :error-messages="errors.unidad_id"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-btn color="success" :disabled="loader" @click="buscar">
            <v-icon>mdi-card-search</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Fechas",
  data: () => ({
    loader: false,
    inicio: undefined,
    fin: undefined,
    unidad: undefined,
    d: new Printd()
  }),
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    buscar() {
      this.$store.commit("SET_ERRORS", {});
      this.loader = true;
      let data = {
        inicio: this.inicio,
        fin: this.fin,
        unidad_id: this.unidad
      };
      window.axios
        .post("unidades/reportes/buscar-fechas", data)
        .then(resp => {
          const el = document.createElement("p");
          el.innerHTML = resp.data;
          this.d.print(el);
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => (this.loader = false));
    }
  }
};
</script>
