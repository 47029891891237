<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="orange lighten-3">
      Liquidaciones Últimos 3 meses
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="items"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fecha }}
        </template>
        <template v-slot:[`item.patron`]="{ item }">
          {{ item.patron | moneda }}
        </template>
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" :href="item.url" target="_blank">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Liquidaciones",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Operador", value: "operador" },
      { text: "Vueltas", value: "vueltas", align: "end" },
      { text: "Boletos", value: "boletos", align: "end" },
      { text: "Usuario", value: "user" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      loading: state => state.Unidades.loader,
      items: state => state.Unidades.historial.liquidaciones,
      historial: state => state.Unidades.historial
    })
  }
};
</script>
