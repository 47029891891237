import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import { Ripple } from "vuetify/lib/directives";
Vue.use(Vuetify);
const opts = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#2a4977",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#00bcd4",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  lang: {
    locales: { es },
    current: "es"
  },
  icons: {
    iconfont: "mdi" // default - only for display purposes
  },
  directives: {
    Ripple
  }
};
export default new Vuetify(opts);
