<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="user">
    <v-form @submit.prevent="sendUser">
      <v-card-text>
        <div class="mt-3">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                type="text"
                label="Usuario"
                v-model="user.user"
                :error-messages="errors.user"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                type="text"
                label="Nombre"
                v-model="user.nombre"
                :error-messages="errors.nombre"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                type="text"
                label="Apellidos"
                v-model="user.apellidos"
                :error-messages="errors.apellidos"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                type="email"
                label="Correo"
                v-model="user.email"
                :error-messages="errors.email"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                outlined
                dense
                type="text"
                label="Teléfono"
                v-model="user.telefono"
                :error-messages="errors.telefono"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                dense
                outlined
                small-chips
                clearable
                label="Tipo de Usuario"
                :items="roles"
                item-value="name"
                item-text="title"
                v-model="user.rol"
                :error-messages="errors.rol"
                v-if="userCan('roles-abilities')"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-text v-if="user.rol !=='admin' && userCan('roles-abilities')">
        <h3>Permisos Adicionales</h3>
        <v-row>
						<v-checkbox
							class="mr-5"
							v-for="(item, k) in abilities"
							hide-details
							v-model="user.userAbilities"
							:label="item.title"
							:value="item.id"
							:key="k"
						/>
					</v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :disabled="loading" type="submit">
          guardar
        </v-btn>
        <v-btn color="error" :disabled="loading" @click="cancelar">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  computed: {
    ...mapState({
      user: state => state.Users.resource,
      loading: state => state.Users.loader,
      roles: state => state.Users.roles,
      abilities: state=> state.Users.abilities,
      errors: state => state.errors
    })
  },
  mounted() {
    if (!this.user) {
      this.cancelar();
    }
  },
  methods: {
    cancelar() {
      this.$router.push({ name: "users" }).catch(() => {});
    },
    ...mapActions("Users", ["sendUser"])
  }
};
</script>
