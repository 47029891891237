var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialog)?_c('NewSalida',{on:{"cerrar":function($event){_vm.dialog = false},"actualizar":_vm.actualizarDatos}}):_c('v-card',{attrs:{"height":"100%"}},[(_vm.items)?_c('v-card-text',[_vm._v(" Fecha: "+_vm._s(_vm._f("fechaLarga")(_vm.ultima.hora_salida))+" "),_c('br'),_vm._v(" Última Respuesta Tracker "+_vm._s(_vm._f("segundos")(_vm.icoglo))+" con "+_vm._s(_vm.resultados)+" registros "),_c('br'),_vm._v(" "+_vm._s(_vm.unidades.length)+" Unidades Trabajaron ")]):_vm._e(),_c('v-card-text',[(
        _vm.user.email == 'developer@zamgod.mx' ||
          _vm.user.email == 'ricardo@alberca.com'
      )?_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","items":_vm.rutas,"label":"Filtrar por Ruta"},model:{value:(_vm.ruta),callback:function ($$v) {_vm.ruta=$$v},expression:"ruta"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":"","items":_vm.unidades,"label":"Filtrar por Unidad"},model:{value:(_vm.unidad),callback:function ($$v) {_vm.unidad=$$v},expression:"unidad"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"info","disabled":_vm.loading},on:{"click":_vm.getAll}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),(_vm.user.roles.includes('monitor'))?_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"small":"","color":"success","disabled":_vm.loading},on:{"click":_vm.nueva}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","items-per-page":_vm.perPage,"items":_vm.items,"headers":_vm.headers,"search":_vm.search,"item-class":_vm.row_classes},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.hora_salida",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.hora_salida))+" ")]}},{key:"item.programada",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.programada))+" ")]}},{key:"item.real",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hora")(item.real))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"success","disabled":_vm.loading},on:{"click":function($event){return _vm.getSalida(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(_vm.userCan('borrar-salida'))?_c('v-btn',{attrs:{"icon":"","color":"error","disabled":_vm.loading},on:{"click":function($event){return _vm.borrar(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }