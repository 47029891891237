<template>
  <v-card>
    <v-card-title primary-title>
      {{ items.length }} Salidas No completadas
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items="items"
        :headers="headers"
        :item-class="row_classes"
        :items-per-page="items.length"
        hide-default-footer
      >
        <template v-slot:[`item.hora_salida`]="{ item }">
          {{ item.hora_salida | hora }}
        </template>
        <template v-slot:[`item.programada`]="{ item }">
          {{ item.programada | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          {{ item.real | hora }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Incompletas",
  props: ["items"],
  data: () => ({
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Unidad", value: "unidad" },
      { text: "Operador", value: "operador" },
      { text: "Salida", value: "hora_salida", filterable: false },
      { text: "Programada", value: "programada", filterable: false },
      { text: "Quemados", value: "quemados", align: "end" },
      { text: "Directos", value: "directos2", align: "center" },
      { text: "Observaciones", value: "observaciones", width: "120px" }
    ]
  })
};
</script>
