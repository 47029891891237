<template>
  <v-card height="100%">
    <v-card-text v-if="items">
      Fecha: {{ ultima.hora_salida | fechaLarga }}
      <v-row>
        <v-col cols="12" md="auto"
          >Última Respuesta Tracker {{ icoglo | segundos }}</v-col
        >
        <v-col cols="12" md="auto">Registros Tracker {{ resultados }} </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="auto"
          v-for="ultima in ultimas"
          :key="ultima.nombre"
          >{{ ultima.nombre }} programó a las {{ ultima.ultima | hora }}</v-col
        >
      </v-row>
      <v-row v-if="quemados">
        <v-col cols="12" v-if="quemados.minutos" md="auto"
          >{{ quemados.unidades.length == 1 ? "La Unidad" : "Las unidades" }}
          <span v-for="(item, i) in quemados.unidades" :key="i">
            {{ item }},
          </span>
          {{ quemados.unidades.length == 1 ? "ha" : "han" }} quemado
          {{ quemados.minutos }} minutos</v-col
        >
        <v-col cols="12" md="auto"
          >Unidades que trabajaron {{ enRuta.unidades }}</v-col
        >
        <v-col cols="12" md="auto"
          >Unidades en Ruta {{ enRuta.trabajando }}</v-col
        >
      </v-row>
    </v-card-text>
    <v-card-text class="pruebas">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items="items"
        :headers="headers"
        :search.sync="search"
        :item-class="row_classes"
        :items-per-page="items.length"
        hide-default-footer
      >
        <template v-slot:[`item.hora_salida`]="{ item }">{{
          item.hora_salida | hora
        }}</template>
        <template v-slot:[`item.programada`]="{ item }">{{
          item.programada | hora
        }}</template>
        <template v-slot:[`item.real`]="{ item }">{{
          item.real | hora
        }}</template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-if="userCan('borrar-salida')">
            <template v-if="!item.terminada">
              <v-btn
                icon
                color="success"
                :disabled="loading"
                @click="getSalida(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="secondary"
                @click="marcarNoTerminada(item)"
                :disabled="loading"
              >
                <v-icon>mdi-calendar-remove</v-icon>
              </v-btn>
              <v-btn
                icon
                color="error"
                :disabled="loading"
                @click="borrar(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                icon
                color="secondary"
                :disabled="loading"
                @click="getSalida(item.id)"
              >
                <v-icon>mdi-check-box-multiple-outline</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <v-btn
              icon
              color="secondary"
              :disabled="loading"
              @click="getSalida(item.id)"
            >
              <v-icon>mdi-check-box-multiple-outline</v-icon>
            </v-btn>
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Monitor",
  data: () => ({
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      headers: state => state.Monitor.headers,
      items: state => state.Monitor.collection,
      time: state => state.Monitor.time,
      loading: state => state.Monitor.loader,
      icoglo: state => state.Monitor.icoglo,
      resultados: state => state.Monitor.resultados,
      ultimas: state => state.Monitor.ultimas,
      quemados: state => state.Monitor.quemados,
      enRuta: state => state.Monitor.enRuta
    }),
    ultima() {
      return this.items[0] ? this.items[0] : { hora_salida: null };
    }
  },
  mounted() {
    this.getAll();
    this.refreshIntervalId = setInterval(() => this.getAll(), 60000);
  },
  beforeDestroy() {
    clearInterval(this.refreshIntervalId);
  },
  methods: {
    ...mapActions("Monitor", [
      "getAll",
      "getSalida",
      "noDioVuelta",
      "solicitar",
      "eliminarSalida"
    ]),
    ...mapMutations("Monitor", ["SET_COLLECTION"]),
    marcarNoTerminada(item) {
      let pregunta = `¿Deseas marcar la salida de la unidad ${item.unidad} como NO TERMINADA?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.noDioVuelta(item.id);
          }
        });
    },
    borrar(item) {
      let pregunta = `¿Deseas eliminar la salida de la unidad ${item.unidad}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarSalida(item.id);
          }
        });
    }
  }
};
</script>
