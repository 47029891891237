<template>
  <v-card height="100%">
    <v-card-actions>
      <v-btn color="primary" @click="$router.go(-1)">Regresar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name:'Solicitar'
  }
</script>
