<template>
  <v-card height="100%">
    <v-card-text>
      Solo puedes tener un máximo de 5 turnos abiertos <br />
      Dinero pendiente de entregar en la oficina: {{ importe | moneda }}
    </v-card-text>
    <v-card-text v-if="items">
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="primary"
            class="mt-5"
            :disabled="loading"
            v-if="puedeAbrir"
            @click="abrirTurno"
          >
            abrir turno
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :item-class="row_classes"
        class="elevation-1"
        :search.sync="search"
        :items-per-page="perPage"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.fecha_entrega`]="{ item }">
          {{ item.fecha_entrega | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <template v-if="item.importe">{{ item.importe | moneda }}</template>
          <template v-else>
            <v-btn small color="primary" @click="reportar(item)">{{
              titleBtn(item)
            }}</v-btn>
          </template>
        </template>
        <template v-slot:[`item.dinero_entregado`]="{ item }">
          {{ item.dinero_entregado | moneda }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="abrir" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="!turno.id">Nuevo Turno</span>
          <span v-else
            >Reportar Minutos del dia {{ turno.fecha | fechaLarga }}</span
          >
        </v-card-title>
        <v-form @submit.prevent="enviarTurno">
          <v-card-text v-if="!turno.id">
            <v-autocomplete
              clearable
              dense
              outlined
              small-chips
              :items="bases"
              label="Seleccione base"
              v-model="base"
              :error-messages="errors.base"
            />
          </v-card-text>
          <v-card-text v-else>
            <v-text-field
              outlined
              dense
              type="number"
              label="Dinero a Reportar"
              v-model.number="turno.importe"
              :error-messages="errors.importe"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="cancelarTurno">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Minutos",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Base", value: "base" },
      { text: "Dinero Reportado", value: "importe", align: "end" },
      { text: "Dinero Entregado", value: "dinero_entregado", align: "end" },
      { text: "Fecha de Entrega", value: "fecha_entrega" },
      { text: "Recibió", value: "recibio" },
      { text: "Observaciones", value: "observaciones" }
    ],
    search: undefined,
    perPage: 15,
    abrir: false,
    turno: {},
    base: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Checador.turnos,
      loading: state => state.Checador.loader,
      bases: state => state.Checador.bases,
      user: state => state.Auth.user,
      errors: state => state.errors
    }),
    puedeAbrir() {
      if (this.items.find(x => x.actual)) {
        return false;
      }
      let faltantes = this.items.filter(x => !x.dinero_entregado);
      if (faltantes.length > 4) {
        return false;
      }
      return true;
    },
    pendientes() {
      return this.items.filter(x => x.importe && !x.dinero_entregado).length;
    },
    importe() {
      if (!this.items) {
        return "";
      }
      let faltantes = this.items.filter(x => x.importe && !x.dinero_entregado);
      return this.items ? window._.sumBy(faltantes, "importe") : "";
    }
  },
  mounted() {
    this.getTurnos();
    if (!this.bases) {
      this.getBases();
    }
  },
  watch: {
    base: function(val) {
      this.turno.base = null;
      if (val) {
        let temporal = this.bases.find(x => x.value == val);
        this.turno.base = temporal.text;
      }
    }
  },
  methods: {
    ...mapMutations(["SET_ERRORS"]),
    ...mapActions(["errores"]),
    ...mapActions("Checador", ["getTurnos", "getBases"]),
    ...mapMutations("Checador", ["SET_TURNOS", "SET_LOADER"]),
    titleBtn(item) {
      return item.actual ? "Cerrar Turno" : "reportar";
    },
    cancelarTurno() {
      this.abrir = false;
      this.turno = {};
      this.base = undefined;
      this.SET_ERRORS([]);
    },
    abrirTurno() {
      this.abrir = true;
      this.base = undefined;
      this.turno = {
        checadora_id: this.user.id
      };
    },
    enviarTurno() {
      this.SET_LOADER(true);
      this.SET_ERRORS([]);
      window.axios
        .post("/checador/abrirTurno", this.turno)
        .then(resp => {
          this.SET_TURNOS(resp.data.turnos);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.cancelarTurno();
        })
        .catch(e => this.errores(e))
        .finally(() => this.SET_LOADER(false));
    },
    reportar(item) {
      if (item.actual) {
        window
          .swal({
            title: "¿Cerrar turno?",
            text: "una vez cerrado no podras dar mas salidas el día de hoy",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
          })
          .then(confirmado => {
            if (confirmado) {
              this.turno = Object.assign({}, item);
              this.abrir = true;
            }
          });
      } else {
        this.turno = Object.assign({}, item);
        this.abrir = true;
      }
    }
  }
};
</script>
