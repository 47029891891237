/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "boletos";
const initialState = () => ({
  collection: undefined,
  resource: undefined,
  loader: false,
  recuperados: undefined,
  unidades: undefined,
  supervisores: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_RECUPERADOS(state, payload) {
      state.recuperados = payload;
    },
    SET_UNIDADES(state, payload) {
      state.unidades = payload;
    },
    SET_SUPERVISORES(state, payload) {
      state.supervisores = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async detalles({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_RECUPERADOS", resp.data.recuperados);
          let nombre = resp.data.title;
          router
            .push({ name: "boletos.details", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async nuevo({ commit }) {
      commit("SET_RESOURCE", {});
      commit("SET_ERRORS", [], { root: true });
      await window.axios.get(`${url}/getUnidades`).then(resp => {
        commit("SET_UNIDADES", resp.data.unidades);
        commit("SET_SUPERVISORES", resp.data.supervisores);
      });
      router.push({ name: "boletos.nuevo" }).catch(() => {});
    },
    async guardar({ commit, dispatch, state }) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      await window.axios
        .post(url, state.resource)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          router.push({ name: "boletos" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
