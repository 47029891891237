<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col v-if="icoglo">
          Última Respuesta Tracker: {{ icoglo.hora | segundos }} <br />
          {{ icoglo.resultados }} resultados
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            :disabled="loading"
            v-if="puedeSalida"
            @click="nuevo"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getSalidas"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="salidas"
        :item-class="row_classes"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
        :items-per-page="perPage"
      >
        <template v-slot:[`item.hora_salida`]="{ item }">
          {{ item.hora_salida | hora }}
        </template>
        <template v-slot:[`item.programada`]="{ item }">
          {{ item.programada | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          {{ item.real | hora }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="secondary"
            :disabled="loading"
            @click="detalleSalida(item.id)"
          >
            <v-icon>mdi-check-box-multiple-outline</v-icon>
          </v-btn>
          <template v-if="item.checador == user.user">
            <v-btn
              icon
              color="error"
              :disabled="loading"
              v-if="item.actions.delete"
              @click="eliminar(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="nueva" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Nueva Salida</span>
        </v-card-title>
        <v-form @submit.prevent="mandar">
          <v-card-text>
            <v-select
              outlined
              dense
              small-chips
              clearable
              label="Ruta"
              :items="rutas"
              item-text="nombre"
              item-value="id"
              v-model="salida.ruta_id"
              :error-messages="errors.ruta_id"
            />
            <v-autocomplete
              outlined
              dense
              small-chips
              clearable
              label="Unidad"
              :items="unidades"
              item-text="unidad"
              item-value="id"
              v-model="unidad"
              :error-messages="errors.unidad_id"
            />
            <v-combobox
              outlined
              dense
              small-chips
              clearable
              label="Operador"
              :items="operadores"
              v-model="operador"
              :error-messages="errors.operador"
            />
            <template v-if="salida.operador">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Boleto Inicial"
                    v-model.number="salida.boleto_inicial"
                    :error-messages="errors.boleto_inicial"
                    :hint="`Último Boleto ${ultimo_boleto}`"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    type="time"
                    label="Hora de Salida"
                    v-model="salida.hora_salida"
                    :error-messages="errors.hora_salida"
                  />
                </v-col>
              </v-row>
              <v-textarea
                outlined
                label="Observaciones"
                v-model="salida.observaciones"
              />
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="nueva = false">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Salidas",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Salida", value: "hora_salida" },
      { text: "Unidad", value: "unidad" },
      { text: "Operador", value: "operador" },
      { text: "LLegada", value: "real" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Quemados", value: "quemados", align: "end" },
      { text: "Directos", value: "directos", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    perPage: 15,
    nueva: false,
    salida: {},
    unidad: undefined,
    ultimo_boleto: undefined,
    operadores: [],
    operador: undefined
  }),
  computed: {
    ...mapState({
      salidas: state => state.Checador.salidas,
      icoglo: state => state.Checador.icoglo,
      loading: state => state.Checador.loader,
      user: state => state.Auth.user,
      allRutas: state => state.Checador.rutas,
      turnos: state => state.Checador.turnos,
      unidades: state => state.Checador.unidades,
      errors: state => state.errors
    }),
    puedeSalida() {
      if (!this.turnos) return false;
      let actual = this.turnos.find(x => x.actual);
      if (actual) {
        return actual.importe ? false : true;
      }
      return false;
    },
    actual() {
      return this.turnos.find(x => x.actual);
    },
    rutas() {
      let data = [];
      if (this.actual) {
        data = this.allRutas.filter(x => x.base == this.actual.base);
      }
      return data;
    }
  },
  mounted() {
    if (!this.turnos) this.getTurnos();
    if (!this.salidas.length) this.getSalidas();
    //this.refreshIntervalId = setInterval(() => this.getSalidas(), 300000);
  },
  watch: {
    unidad: function(val) {
      this.salida.unidad_id = val;
      this.operador = undefined;
      this.ultimo_boleto = undefined;
      this.operadores = [];
      this.SET_LOADER(true);
      if (val) {
        window.axios
          .get(`unidades/operadores/${val}`)
          .then(resp => {
            this.operadores = resp.data.operadores;
            this.ultimo_boleto = resp.data.ultimo_boleto;
          })
          .finally(() => this.SET_LOADER(false));
      }
    },
    operador: function(val) {
      this.salida.operador = val;
    }
  },
  methods: {
    ...mapActions("Checador", [
      "getSalidas",
      "getTurnos",
      "detalleSalida",
      "deleteSalida"
    ]),
    ...mapMutations("Checador", ["SET_LOADER"]),
    ...mapMutations(["SET_ERRORS"]),
    ...mapActions(["errores"]),
    nuevo() {
      this.nueva = true;
      this.salida = {};
      this.SET_ERRORS([]);
      this.unidad = undefined;
      this.SET_LOADER(false);
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar la salida de la unidad ${item.unidad}?`,
        text = "Esta acción no puede deshacerse";
      window
        .swal({
          title: pregunta,
          text: text,
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.deleteSalida(item.id);
          }
        });
    },
    mandar() {
      this.SET_ERRORS([]);
      this.SET_LOADER(true);
      window.axios
        .post(`checador/crearSalida`, this.salida)
        .then(resp => {
          this.nueva = false;
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.getSalidas();
        })
        .catch(e => this.errores(e))
        .finally(() => this.SET_LOADER(false));
    }
  }
};
</script>
