<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="orange lighten-4">
      Pagados Últimos 3 meses
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="ultimos"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        :item-class="getClasses"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="error"
            :disabled="loading"
            v-if="item.eliminable"
            @click="borrar(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Pagados",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Unidad", value: "unidad" },
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Usuario", value: "user" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Tarjetas.collection,
      loading: state => state.Tarjetas.loader
    })
  },
  mounted() {
    if (!this.items) this.ultimos();
  },
  methods: {
    ...mapActions("Tarjetas", ["ultimos",'eliminarTarjeta']),
    getClasses(item) {
      let clase = null;
      if (item.atrasado) clase = "red lighten-5";
      return clase;
    },
    borrar(item) {
      let pregunta = `¿Deseas cancelar la tarjeta ${item.concepto} de la unidad ${item.unidad}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarTarjeta(item.id);
          }
        });
    }
  }
};
</script>
