<template>
  <v-card height="100%" v-if="liquidacion">
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="12" md="2">
          <v-text-field
            outlined
            dense
            type="date"
            label="Fecha"
            v-model="fecha"
            :error-messages="errors.fecha"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            dense
            outlined
            small-chips
            clearable
            label="Unidad"
            :items="unidades"
            v-model="unidad"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-btn color="success" :disabled="buscar" @click="buscarLiquidacion">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="salidas">
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header color="red lighten-4">
              Salidas : {{ salidas.length }}
              <span class="ml-3">Vueltas: {{ salidas.length / 2 }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                dense
                mobile-breakpoint="0"
                :headers="headers1"
                :items="salidas"
                hide-default-footer
                class="elevation-1 mt-3"
              >
                <template v-slot:[`item.hora_salida`]="{ item }">
                  {{ item.hora_salida | hora }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="blue lighten-4">
              Gastos Total: {{ importeGastos | moneda }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form @submit.prevent="agregar">
                <v-row class="mt-3">
                  <v-col cols="12" md="4">
                    <v-combobox
                      dense
                      outlined
                      small-chips
                      clearable
                      label="Concepto"
                      :items="conceptos"
                      v-model.number="descripcion"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      label="Importe"
                      v-model.number="importe"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn color="success" :disabled="loading" type="submit">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-data-table
                    dense
                    mobile-breakpoint="0"
                    class="elevation-1"
                    hide-default-footer
                    :headers="headers2"
                    :items="details"
                  >
                    <template v-slot:[`item.importe`]="{ item }">
                      {{ item.importe | moneda }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn
                        icon
                        color="red"
                        :disabled="loading"
                        @click="eliminar(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header color="green lighten-4">
              Datos Generales
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form @submit.prevent="storeLiquidacion">
                <v-row class="mt-3">
                  <v-col cols="12" md="8">
                    <v-select
                      dense
                      outlined
                      clearable
                      small-chips
                      label="Operador"
                      :items="operadores"
                      v-model="liquidacion.operador"
                      :error-messages="errors.operador"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      type="time"
                      label="Hora de Entrada"
                      v-model="liquidacion.hora_entrada"
                      :error-messages="errors.hora_entrada"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      label="Boleto Inicial"
                      v-model.number="liquidacion.boleto_inicial"
                      :error-messages="errors.boleto_inicial"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      label="Boleto Final"
                      v-model.number="liquidacion.boleto_final"
                      :error-messages="errors.boleto_final"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      step="0.1"
                      label="Precio Boleto"
                      v-model.number="liquidacion.precio_boleto"
                      :error-messages="errors.precio_boleto"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      outlined
                      dense
                      type="number"
                      step="0.5"
                      label="Vueltas"
                      v-model.number="liquidacion.vueltas"
                      :error-messages="errors.vueltas"
                    />
                  </v-col>
                  <v-col>
                    <v-textarea
                      outlined
                      label="Observaciones"
                      v-model="liquidacion.observaciones"
                      :error-messages="errors.observaciones"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="success" :disabled="loading" type="submit">
                      guardar
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ml-3"
                      :disabled="loading"
                      @click="cancelar"
                    >
                      cancelar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-3">
                  <v-col>
                    Importe Gastos: {{ importeGastos | moneda }}
                    <br />
                    <span v-if="boletos">
                      Total de Boletos: {{ boletos }}
                    </span>
                    <span v-if="boletos" class="ml-3">
                      Importe: {{ importeBoletos | moneda }}
                    </span>
                    <br />
                    % de Comisión: {{ porcentaje }}
                    <span class="ml-3"
                      >Comisión Operador: {{ comisionOperador | moneda }}</span
                    >
                    <br />
                    Total Patrón: {{ patron | moneda }}
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              color="orange lighten-4"
              v-if="mostrarBoletos.length"
            >
              Boletos Pendientes {{ importeBoletosPendientes | moneda }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                dense
                class="elevation-1 mt-3"
                mobile-breakpoint="0"
                :headers="headers3"
                :items="mostrarBoletos"
                :search.sync="search"
                :items-per-page="mostrarBoletos.length"
                :item-class="row_classes"
                hide-default-footer
              >
                <template v-slot:[`item.id`]="{ item }">
                  {{ item.id | folio }}
                </template>
                <template v-slot:[`item.fecha`]="{ item }">
                  {{ item.fecha | fechaLarga }}
                </template>
                <template v-slot:[`item.importe`]="{ item }">
                  {{ item.importe | moneda }}
                </template>
                <template v-slot:[`item.pendiente`]="{ item }">
                  {{ item.pendiente | moneda }}
                </template>
                <template v-slot:[`item.recuperado`]="{ item }">
                  {{ item.recuperado | moneda }}
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Nueva",
  data: () => ({
    fecha: undefined,
    unidad: undefined,
    buscar: true,
    descripcion: undefined,
    importe: undefined,
    headers1: [
      { text: "Ruta", value: "ruta" },
      { text: "Operador", value: "operador" },
      { text: "Hora Salida", value: "hora_salida" },
      { text: "Terminada", value: "terminada" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Boleto Inicial", value: "boleto_inicial" },
      { text: "Checador", value: "checador" }
    ],
    headers2: [
      { text: "Descripción", value: "descripcion" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Eliminar", value: "actions", align: "end" }
    ],
    boleto_inicial: undefined,
    boleto_final: undefined,
    search: undefined,
    headers3: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Supervisor", value: "supervisor" },
      { text: "Boletos", value: "boletos", align: "end" },
      { text: "Operador", value: "operador" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Recuperado", value: "recuperado", align: "end" },
      { text: "Pendiente", value: "pendiente", align: "end" },
      { text: "Usuario", value: "usuario" }
    ]
  }),
  computed: {
    ...mapState({
      liquidacion: state => state.Liquidaciones.resource,
      unidades: state => state.Liquidaciones.unidades,
      loading: state => state.Liquidaciones.loader,
      errors: state => state.errors,
      salidas: state => state.Liquidaciones.salidas,
      conceptos: state => state.Liquidaciones.conceptos,
      details: state => state.Liquidaciones.resource.details,
      precio_boleto: state => state.Liquidaciones.precioBoleto,
      comision: state => state.Liquidaciones.comision,
      boletos_pendientes: state => state.Liquidaciones.boletos
    }),
    operadores() {
      return [...new Set(this.salidas.map(x => x.operador))];
    },
    boletos() {
      let boletos = 0;
      if (this.liquidacion.boleto_inicial && this.liquidacion.boleto_final)
        boletos =
          this.liquidacion.boleto_final - this.liquidacion.boleto_inicial;
      return boletos;
    },
    importeBoletos() {
      return this.boletos * this.liquidacion.precio_boleto;
    },
    importeGastos() {
      return window._.sumBy(this.details, "importe");
    },
    comisionOperador() {
      let importe = this.importeBoletos;
      if (this.comision == 0) return 0;
      if (this.comision) {
        return importe * this.comision * 0.01;
      } else {
        if (this.boletos < 320) {
          return importe * 0.15;
        } else {
          return importe * 0.2;
        }
      }
    },
    porcentaje() {
      if (this.comision) return this.comision;
      if (this.comision == 0) return 0;
      if (this.boletos < 320) {
        return 15;
      } else {
        return 20;
      }
    },
    patron() {
      return this.importeBoletos - this.importeGastos - this.comisionOperador;
    },
    importeBoletosPendientes() {
      return window._.sumBy(this.mostrarBoletos, "pendiente");
    },
    mostrarBoletos() {
      return this.boletos_pendientes.filter(x => x.pendiente > 0);
    }
  },
  mounted() {
    if (!this.liquidacion) {
      this.$router.push({ name: "liquidaciones" });
    }
  },
  watch: {
    fecha: function(val) {
      this.buscar = true;
      this.liquidacion.fecha = val;
      this.SET_SALIDAS(undefined);
      if (val && this.unidad) this.buscar = false;
    },
    unidad: function(val) {
      this.buscar = true;
      this.liquidacion.unidad_id = val;
      this.SET_SALIDAS(undefined);
      if (val && this.fecha) this.buscar = false;
    },
    salidas: function(val) {
      this.boleto_inicial = undefined;
      this.liquidacion.operador = undefined;
      if (val) {
        this.boleto_inicial = window._.minBy(
          val,
          "boleto_inicial"
        ).boleto_inicial;
        this.boleto_final = window._.maxBy(
          val,
          "boleto_inicial"
        ).boleto_inicial;
      }
    },
    boleto_inicial: function(val) {
      this.liquidacion.boleto_inicial = undefined;
      if (val) this.liquidacion.boleto_inicial = val;
    },

    precio_boleto: function(val) {
      this.liquidacion.precio_boleto = undefined;
      if (val) this.liquidacion.precio_boleto = val;
    }
  },
  methods: {
    ...mapActions("Liquidaciones", ["buscarLiquidacion", "storeLiquidacion"]),
    ...mapMutations("Liquidaciones", ["SET_SALIDAS"]),
    cancelar() {
      this.$router.push({ name: "liquidaciones" });
    },
    agregar() {
      let importe = this.importe,
        descripcion = this.descripcion.toUpperCase();
      if (importe && descripcion) {
        this.details.push({ importe, descripcion });
        this.importe = undefined;
        this.descripcion = undefined;
      }
    },
    eliminar(item) {
      let details = this.details,
        index = details.findIndex(x => x.descripcion == item.descripcion);
      details.splice(index, 1);
    }
  }
};
</script>