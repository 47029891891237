<template>
  <div>
    <v-card height="100%">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-btn
              small
              color="success"
              class="mt-5"
              :disabled="loading"
              @click="nueva"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="mt-5 ml-3"
              @click="getAll"
              :disabled="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" xl="4" offset-xl="4">
            <v-container>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Busqueda"
                single-line
                hide-details
              />
            </v-container>
          </v-col>
        </v-row>
        <v-data-table
          dense
          class="elevation-1 mt-3"
          mobile-breakpoint="0"
          :headers="headers"
          :items="items"
          :search.sync="search"
        >
          <template v-slot:[`item.fecha`]="{ item }">
            {{ item.fecha | fecha }}
          </template>
          <template v-slot:[`item.patron`]="{ item }">
            {{ item.patron | moneda }}
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ item.id | folio }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon color="primary" @click="print(item)">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
            <v-btn icon color="red" :disabled="loading" @click="eliminar(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Printd } from "printd";
export default {
  name: "Liquidaciones",
  data: () => ({
    d: undefined
  }),
  computed: {
    ...mapState({
      headers: state => state.Liquidaciones.headers,
      items: state => state.Liquidaciones.collection,
      loading: state => state.Liquidaciones.loader
    }),
    search: {
      // getter
      get: function() {
        return this.$store.state.Liquidaciones.search;
      },
      // setter
      set: function(newValue) {
        this.$store.commit("Liquidaciones/setSearch", newValue);
      }
    }
  },
  mounted() {
    this.getAll();
    this.d = new Printd();
    const { contentWindow } = this.d.getIFrame();

    contentWindow.addEventListener("beforeprint", () =>
      console.log("before print event!")
    );
    contentWindow.addEventListener("afterprint", () =>
      console.log("after print event!")
    );
  },
  methods: {
    ...mapActions("Liquidaciones", ["getAll", "nueva", "deleteLiquidacion"]),
    eliminar(item) {
      let pregunta = `¿Deseas eliminar la liquidacion de la unidad ${item.unidad}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.deleteLiquidacion(item.id);
          }
        });
    },
    print(item) {
      const el = document.createElement("p");
      el.innerHTML = item.html;
      this.d.print(el);
    }
  }
};
</script>
<style lang="scss" escoped>
@media screen {
  #base {
    display: none;
  }
}
</style>
