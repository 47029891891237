<template>
  <v-card height="100%">
    <v-card-text>
      <v-form @submit.prevent="buscar">
        <v-row>
          <v-col cols="12" md="4" xl="3">
            <v-text-field
              dense
              outlined
              type="date"
              v-model="inicio"
              label="Fecha Inicial"
              :disabled="loader"
              :max="today"
            />
          </v-col>
          <v-col cols="12" md="4" xl="3">
            <v-text-field
              dense
              outlined
              type="date"
              v-model="fin"
              label="Fecha Final"
              :disabled="loader"
              :max="today"
            />
          </v-col>
          <v-col cols="12" md="4" xl="3">
            <v-btn color="primary" :disabled="loader" type="submit">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="mb-3">
          <SalidasUnidad :items="salidasUnidad" v-if="salidasUnidad" />
        </v-col>
        <v-col cols="12" xl="6">
          <Salidas :salidas="salidas" v-if="salidas" />
        </v-col>
        <v-col cols="12" xl="6">
          <Unidades :unidades="unidades" v-if="unidades" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Salidas from "./estadisticas/Salidas.vue";
import Unidades from "./estadisticas/Unidades.vue";
import SalidasUnidad from "./estadisticas/SalidasUnidad.vue";
export default {
  name: "Estadísticas",
  components: { Salidas, Unidades, SalidasUnidad },
  data: () => ({
    salidas: undefined,
    unidades: undefined,
    salidasUnidad: undefined,
    inicio: undefined,
    fin: undefined,
    loader: false
  }),
  computed: {
    today() {
      return new Date().toISOString().split("T")[0];
    }
  },
  mounted() {
    let test = this.getMonday(new Date())
      .toISOString()
      .split("T")[0];
    this.inicio = test;
    this.fin = this.today;
    this.buscar();
  },
  methods: {
    buscar() {
      this.loader = true;
      this.salidas = undefined;
      this.unidades = undefined;
      this.salidasUnidad = undefined;
      window.axios
        .get("estadisticas", {
          params: {
            inicio: this.inicio,
            fin: this.fin
          }
        })
        .then(resp => {
          this.inicio = resp.data.inicio;
          this.fin = resp.data.fin;
          this.salidas = resp.data.salidas;
          this.unidades = resp.data.unidades;
          this.salidasUnidad = resp.data.salidasUnidad;
        })
        .catch(e => this.$store.dispatch("errores", e))
        .finally(() => (this.loader = false));
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(d.setDate(diff));
    }
  }
};
</script>
