var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_vm._v(" Solo puedes tener un máximo de 5 turnos abiertos "),_c('br'),_vm._v(" Dinero pendiente de entregar en la oficina: "+_vm._s(_vm._f("moneda")(_vm.importe))+" ")]),(_vm.items)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.puedeAbrir)?_c('v-btn',{staticClass:"mt-5",attrs:{"small":"","color":"primary","disabled":_vm.loading},on:{"click":_vm.abrirTurno}},[_vm._v(" abrir turno ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","xl":"4","offset-xl":"4"}},[_c('v-container',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.items,"item-class":_vm.row_classes,"search":_vm.search,"items-per-page":_vm.perPage},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fechaLarga")(item.fecha))+" ")]}},{key:"item.fecha_entrega",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fechaLarga")(item.fecha_entrega))+" ")]}},{key:"item.importe",fn:function(ref){
var item = ref.item;
return [(item.importe)?[_vm._v(_vm._s(_vm._f("moneda")(item.importe)))]:[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.reportar(item)}}},[_vm._v(_vm._s(_vm.titleBtn(item)))])]]}},{key:"item.dinero_entregado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneda")(item.dinero_entregado))+" ")]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.abrir),callback:function ($$v) {_vm.abrir=$$v},expression:"abrir"}},[_c('v-card',[_c('v-card-title',[(!_vm.turno.id)?_c('span',{staticClass:"headline"},[_vm._v("Nuevo Turno")]):_c('span',[_vm._v("Reportar Minutos del dia "+_vm._s(_vm._f("fechaLarga")(_vm.turno.fecha)))])]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.enviarTurno($event)}}},[(!_vm.turno.id)?_c('v-card-text',[_c('v-autocomplete',{attrs:{"clearable":"","dense":"","outlined":"","small-chips":"","items":_vm.bases,"label":"Seleccione base","error-messages":_vm.errors.base},model:{value:(_vm.base),callback:function ($$v) {_vm.base=$$v},expression:"base"}})],1):_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","label":"Dinero a Reportar","error-messages":_vm.errors.importe},model:{value:(_vm.turno.importe),callback:function ($$v) {_vm.$set(_vm.turno, "importe", _vm._n($$v))},expression:"turno.importe"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"type":"submit"}},[_vm._v(" guardar ")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.loading},on:{"click":_vm.cancelarTurno}},[_vm._v(" cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }