import Vue from "vue";
import VueRouter from "vue-router";
import BasicRoutes from "./basicos";
import UserRoutes from "./users";
import UnidadesRoutes from "./unidades";
import RutasRoutes from "./rutas";
import SalidasRoutes from "./salidas";
import MonitorRoutes from "./monitor";
import ChecadorRoutes from "./checadores";
import Liquidaciones from "./liquidaciones";
import TarjetasRoutes from "./tarjetas";
import GastosRoutes from "./gastos";
import BoletosRoutes from "./boletos";
import ReportesRoutes from "./reportes";
import Test from './../views/Test.vue';

Vue.use(VueRouter);

const routes = [
  ...BasicRoutes,
  ...UserRoutes,
  ...UnidadesRoutes,
  ...RutasRoutes,
  ...SalidasRoutes,
  ...MonitorRoutes,
  ...ChecadorRoutes,
  ...Liquidaciones,
  ...TarjetasRoutes,
  ...GastosRoutes,
  ...BoletosRoutes,
  ...ReportesRoutes,
  {
    path: "/Pruebas",
    name: "pruebas",
    component: Test,
    meta: {
      name: "Componente de Pruebas"
    }
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  routes,
  linkExactActiveClass: "nav-item active",
  mode: "history"
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
