<template>
  <v-card>
    <v-card-title primary-title>
      Checadas Manuales
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            dense
            outlined
            clearable
            label="Filtrar por usuario"
            v-model="user"
            :items="users"
          />
        </v-col>
        <v-col>
          <v-select
            dense
            outlined
            clearable
            label="Filtrar por Unidad"
            v-model="unidad"
            :items="unidades"
          />
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :items-per-page="perPage"
        class="elevation-1"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Manuales",
  props: ["checadas"],
  data: () => ({
    headers: [
      { text: "Usuario", value: "user" },
      { text: "Unidad", value: "unidad_id" },
      { text: "Cantidad", value: "checadas", align: "end" }
    ],
    perPage: 5,
    unidad: undefined,
    user: undefined
  }),
  computed: {
    items() {
      let data = this.checadas ? this.checadas : [];
      if (this.user) {
        data = data.filter(x => x.user == this.user);
      }
      if (this.unidad) {
        data = data.filter(x => x.unidad_id == this.unidad);
      }
      return data.sort((a, b) =>
        a.unidad_id > b.unidad_id ? 1 : b.unidad_id > a.unidad_id ? -1 : 0
      );
    },
    users() {
      return [...new Set(this.checadas.map(x => x.user))].sort();
    },
    unidades() {
      return [...new Set(this.checadas.map(x => x.unidad_id))].sort();
    }
  }
};
</script>
