/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "puntoChecados";
const initialState = () => ({
  headers: undefined,
  collection: [],
  resource: undefined,
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload = true) {
      state.loader = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", []);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    newResource({ commit }) {
      commit("SET_RESOURCE", {});
      router.push({ name: "puntos.create" }).catch(() => {});
    },
    async getResource({ commit, dispatch }, id) {
      commit("SET_LOADER");
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          router.push({ name: "puntos.edit", params: { id } }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async sendResource({ commit, dispatch, state }) {
      commit("SET_ERRORS", {}, { root: true });
      commit("SET_LOADER");
      let resource = state.resource;
      if (!resource.id) {
        await window.axios
          .post(url, resource)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            router.push({ name: "puntos" });
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${resource.id}`, resource)
            .then(resp => {
               window.swal(resp.data.message, { icon: "success", timer: 1000 });
               router.push({ name: "puntos" });
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    }
  }
};
