<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="blue lighten-3">
      Pagos de Tarjetas
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" xl="4" offset-xl="8">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="items"
        :item-class="getClasses"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
        {{ item.importe | moneda }}
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id | folio }}
      </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Tarjetas",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Usuario", value: "user" }
    ]
  }),
  computed: {
    ...mapState({
      loading: state => state.Unidades.loader,
      items: state => state.Unidades.historial.tarjetas
    })
  },
   methods: {
    getClasses(item) {
      let clase = null;
      if (item.atrasado) clase = "red lighten-5";
      return clase;
    }
  }
};
</script>
