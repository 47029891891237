<template>
  <v-card height="100%">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            :disabled="loading"
            @click="nueva"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :items="items"
        :headers="headers"
        :search.sync="search"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="success" :disabled="loading" @click="editar(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="error" :disabled="loading">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Rutas",
  data: () => ({
    search: undefined
  }),
  computed: {
    ...mapState({
      headers: state => state.Rutas.headers,
      items: state => state.Rutas.collection,
      loading: state=>state.Rutas.loader
    })
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Rutas", ["getAll", "nueva", "editar"])
  }
};
</script>
