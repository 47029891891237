/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "checador";
const initialState = () => ({
  loader: false,
  bases: undefined,
  base: undefined,
  salidas: [],
  turnos: undefined,
  rutas: undefined,
  unidades: undefined,
  salida: undefined,
  checadas: undefined,
  icoglo: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_BASES(state, payload) {
      state.bases = payload;
    },
    SET_BASE(state, payload) {
      state.base = payload;
    },
    SET_SALIDAS(state, payload) {
      state.salidas = payload;
    },
    SET_TURNOS(state, payload) {
      state.turnos = payload;
    },
    SET_RUTAS(state, payload) {
      state.rutas = payload;
    },
    SET_UNIDADES(state, payload) {
      state.unidades = payload;
    },
    SET_SALIDA(state, payload) {
      state.salida = payload;
    },
    SET_CHECADAS(state, payload) {
      state.checadas = payload;
    },
    SET_ICOGLO(state, payload) {
      state.icoglo = payload;
    }
  },
  actions: {
    async getBases({ commit, dispatch }) {
      await window.axios
        .get(`${url}/bases`)
        .then(resp => {
          commit("SET_BASES", resp.data);
          window.axios.get("rutas").then(resp => {
            commit("SET_RUTAS", resp.data.collection);
            commit("SET_UNIDADES", resp.data.unidades);
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async getSalidas({ commit, dispatch, state }) {
      commit("SET_SALIDAS", []);
      commit("SET_LOADER", true);
      commit("SET_ICOGLO", undefined);
      await window.axios
        .post(`${url}/salidas`)
        .then(resp => {
          commit("SET_SALIDAS", resp.data.salidas);
          let hora = resp.data.hora,
            resultados = resp.data.resultados;
          commit("SET_ICOGLO", { hora, resultados });
          commit("SET_RUTAS", resp.data.rutas);
          commit("SET_UNIDADES", resp.data.unidades);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async deleteSalida({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      window.axios
        .delete(`/salidas/${id}`)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          dispatch("getSalidas");
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async getTurnos({ commit, dispatch }) {
      commit("SET_LOADER", true);
      commit("SET_TURNOS", undefined);
      window.axios
        .get(`${url}/turnos`)
        .then(resp => commit("SET_TURNOS", resp.data.turnos))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async detalleSalida({ commit, dispatch }, id) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      await window.axios
        .get(`salidas/${id}`)
        .then(resp => {
          commit("SET_SALIDA", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
          let nombre = `Detalle de la salida ${resp.data.resource.id}`;
          router
            .push({ name: "salida.checador", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async setObservaciones({ commit, dispatch, state }) {
      commit("SET_LOADER", true);
      let salida = state.salida;
      await window.axios
        .post(`monitor/setObservaciones/${salida.id}`, salida)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_SALIDA", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async crearSalida({ commit, dispatch }, salida) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      await window.axios
        .post(`${url}/crearSalida`, salida)
        .then(resp => {})
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
