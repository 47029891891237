<template>
  <div>
    <h3 class="text-center">
      Tarjetas Anteriores a {{ new Date().getFullYear() }}
    </h3>
    <v-row>
      <v-col cols="12" xl="4" offset-xl="8">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      mobile-breakpoint="0"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
      sort-desc
      sort-by="fecha"
    >
      <template v-slot:[`item.pagar`]="{ item }">
        <v-simple-checkbox
          v-model="item.pagar"
          :ripple="false"
          color="success"
        />
      </template>
      <template v-slot:[`item.importe`]="{ item }">{{
        item.importe | moneda
      }}</template>
    </v-data-table>
    <v-row>
      <v-col>
        <v-btn color="success" class="my-3 mx-3" @click="$emit('regresar')"
          >tarjetas {{ new Date().getFullYear() }}</v-btn
        >
        <v-btn
          color="primary"
          class="my-3 mx-3"
          @click="abrir = true"
          v-if="seleccionados.length"
        >
          Pagar {{ seleccionados.length }} tarjetas anteriores
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="abrir" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Guardar Tarjetas</span>
        </v-card-title>
        <v-form @submit.prevent="pagar">
          <v-card-text>
            <v-text-field
              outlined
              dense
              type="date"
              label="Fecha del pago"
              v-model="fecha"
              :error-messages="errors.fecha"
            />
            <span v-for="(item, i) in conceptos" :key="i">
              {{ item }}<br />
            </span>
            importe a pagar:
            {{ monto | moneda }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="listo" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="cancelarTurno">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Anteriores",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Seleccionar", value: "pagar", align: "end" }
    ],
    abrir: false,
    fecha: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Tarjetas.resultados.atrasadas,
      errors: state => state.errors,
      loading: state => state.Tarjetas.loader,
      importeTarjet: state => state.Tarjetas.resultados.tarjetas,
      unidad_id: state => state.Tarjetas.resultados.unidad_id
    }),
    seleccionados() {
      return this.items.filter(x => x.pagar);
    },
    conceptos() {
      return [...new Set(this.seleccionados.map(x => x.concepto))];
    },
    listo() {
      if (this.loading) return true;
      return this.fecha ? false : true;
    },
    monto() {
      if (!this.seleccionados.length) return null;
      return window._.sumBy(this.seleccionados, "importe");
    }
  },
  mounted() {
    this.items.forEach(x => (x.pagar = false));
  },
  methods: {
    ...mapMutations(["SET_ERRORS"]),
    ...mapActions(["errores"]),
    pagar() {
      let data = {
        unidad_id: this.unidad_id,
        seleccionados: this.seleccionados,
        fecha: this.fecha
      };
      this.SET_ERRORS([]);
      window.axios
        .post("tarjetas/pagar", data)
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          this.$emit("listo");
        })
        .catch(e => {
          this.errores(e);
        });
    },
    cancelarTurno() {
      this.abrir = false;
    }
  }
};
</script>
