import Index from "./../views/boletos/index.vue";
import Detalle from "./../views/boletos/detalle.vue";
import Nuevo from "./../views/boletos/nuevo.vue";
const routes = [
  {
    path: "/Boletos",
    name: "boletos",
    component: Index,
    meta: {
      name: "Control de Boletos Pagados"
    }
  },
  {
    path: "/Boletos/detalle/:id",
    name: "boletos.details",
    component: Detalle,
    meta: {
      name: "Detalles de Boletos pagados a Supervisor"
    }
  },
  {
    path: "/Boletos/nuevo",
    name: "boletos.nuevo",
    component: Nuevo,
    meta: {
      name: "Nuevo Pago de Boletos a Supervisor"
    }
  }
];
export default routes;
