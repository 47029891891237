<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="green lighten-3">
      Busqueda
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row class="mt-4">
        <v-col cols="12" md="2">
          <v-text-field
            dense
            outlined
            clearable
            type="date"
            label="Fecha Inicial"
            v-model="inicio"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            dense
            outlined
            clearable
            type="date"
            label="Fecha Final"
            v-model="fin"
          />
        </v-col>
        <v-col>
          <v-btn color="success" @click="buscar">Buscar</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="boletos">
        <v-col>
          Dias Trabajados en el periodo: {{ diasTrabajados }}<br />
          Vueltas: {{ vueltas }}<br />
          Boletos: {{ boletos }}*<br />
          *Datos de las liquidaciones
        </v-col>
        <v-col>
          Liquidaciones: {{ liquidaciones }}<br />
          Combustible: {{combustible|moneda}}<br />
          Comisiones Operador: {{ comisiones | moneda }}<br />
          Total de Gastos: {{ gastos | moneda }}**<br />
          Total de las liquidaciones: {{ total | moneda }}<br />
          **Sin Combustible ni checadoras
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Busqueda",
  data: () => ({
    inicio: undefined,
    fin: undefined,
    boletos: undefined,
    comisiones: undefined,
    combustible: undefined,
    diasTrabajados: undefined,
    gastos: undefined,
    liquidaciones: undefined,
    total: undefined,
    vueltas: undefined
  }),
  computed: {
    ...mapState({
      unidad: state => state.Unidades.resource
    })
  },
  methods: {
    buscar() {
      this.boletos = undefined;
      let data = { inicio: this.inicio, fin: this.fin },
        id = this.unidad.id;
      window.axios
        .post(`/unidades/busqueda/${id}`, data)
        .then(resp => {
          this.boletos = resp.data.boletos;
          this.comisiones = resp.data.comisiones;
          this.diasTrabajados = resp.data.diasTrabajados;
          this.gastos = resp.data.gastos;
          this.liquidaciones = resp.data.liquidaciones;
          this.total = resp.data.total;
          this.vueltas = resp.data.vueltas;
          this.combustible = resp.data.combustible;
        })
        .catch(e => this.$store.commit("errores", e));
    }
  }
};
</script>
