/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "rutas";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  puntosRuta: undefined,
  puntos: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_PUNTOS_RUTA(state, payload) {
      state.puntosRuta = payload;
    },
    SET_PUNTOS(state, payload) {
      state.puntos = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("SET_PUNTOS", resp.data.puntos);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    nueva({ commit }) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      commit("SET_RESOURCE", {});
      commit("SET_PUNTOS_RUTA", []);
      router.push({ name: "ruta.create" }).catch(() => {});
      commit("SET_LOADER", false);
    },
    async editar({ commit, dispatch }, item) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      let id = item.id,
        nombre = `Editando ruta ${item.nombre}`;
      await window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_PUNTOS_RUTA", resp.data.puntosRuta);

          router
            .push({ name: "ruta.details", params: { id, nombre } })
            .catch(() => {});
          commit("SET_LOADER", false);
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    cancelar({ commit }) {
      commit("SET_LOADER", false);
      commit("SET_ERRORS", [], { root: true });
      commit("SET_RESOURCE", undefined);
      router.push({ name: "rutas" }).catch(() => {});
    },
    async sendRuta({ commit, dispatch, state }) {
      let ruta = state.resource;
      commit("SET_ERRORS", [], { root: true });
      commit("SET_LOADER", true);
      if (!ruta.id) {
        await window.axios
          .post(url, ruta)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            commit("SET_COLLECTION", resp.data.collection);
            commit("SET_RESOURCE", resp.data.resource);
            commit("SET_PUNTOS_RUTA", []);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        await window.axios
          .put(`${url}/${ruta.id}`, ruta)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            commit("SET_COLLECTION", resp.data.collection);
            commit("SET_RESOURCE", resp.data.resource);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    }
  }
};
