<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          dense
          outlined
          label="Fecha"
          type="date"
          v-model="date"
          :error-messages="errors.date"
          :disabled="loader"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field
          dense
          outlined
          clearable
          label="Buscar"
          v-model="search"
          append-icon="mdi-magnify"
          :disabled="loader"
          hide-details="auto"
        />
      </v-col>
      <v-col>
        <v-btn color="info" class="mr-2" :disabled="loader" @click="getData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          color="secondary"
          v-if="items.length"
          :disabled="loader"
          @click="imprimir"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      mobile-breakpoint="0"
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-1"
      :search.sync="search"
      :loading="loader"
      @pagination="paginadoChange"
      :page="miPaginado.page"
      :items-per-page="miPaginado.itemsPerPage"
    >
    </v-data-table>
    <PaginadoSimple :paginado="miPaginado" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { Printd } from "printd";
import { isMobile } from "mobile-device-detect";
export default {
  name: "HojaTrabajo",
  data: () => ({
    date: null,
    items: [],
    headers: [
      { text: "Unidad", value: "unidad" },
      { text: "Operadores", value: "operadores" },
      { text: "Salidas", value: "salidas", width: "5", align: "end" },
      { text: "Terminadas", value: "terminadas", width: "5", align: "end" },
      {
        text: "NO Terminadas",
        value: "no_terminadas",
        width: "5",
        align: "end"
      },
      { text: "Quemados", value: "quemados", width: "5", align: "end" },
      { text: "Directos", value: "directos", width: "5", align: "end" }
    ],
    search: null,
    miPaginado: { itemsPerPage: 15 },
    url: null,
    d: new Printd()
  }),
  computed: {
    ...mapState({
      loader: state => state.loader,
      errors: state => state.errors
    })
  },
  methods: {
    ...mapActions(["errores", "message"]),
    ...mapMutations(["setErrors", "setLoader"]),
    getData() {
      this.setLoader();
      this.items = [];
      let url = "reportes/hojaTrabajo";
      window.axios
        .get(url, { params: { date: this.date } })
        .then(resp => {
          this.items = resp.data.collection;
          this.url = resp.data.url;
        })
        .catch(e => this.errores(e))
        .finally(() => this.setLoader(false));
    },
    paginadoChange(item) {
      this.miPaginado = item;
    },
    imprimir() {
      window.open(this.url);
    }
  },
  watch: {
    date(val) {
      if (val) this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  beforeMount() {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    this.date = date.toISOString().split("T")[0];
  }
};
</script>
