<template>
  <v-card>
    <v-card-title primary-title>
      {{items.datasets.length}} Unidades
    </v-card-title>
    <v-card-text>
      <bar-chart
        :chart-data="items"
        :options="options"
        :height="315"
      ></bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "./../BarChart.js";
export default {
  name: "Vueltas",
  props: ["items"],
  components: { BarChart },
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 0.5
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  })
};
</script>
