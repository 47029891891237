<!-- eslint-disable -->
<template>
  <v-card height="100%">
    <v-card-text>
      <v-row v-if="user.email == 'developer@zamgod.mx'">
        <v-col cols="12" md="6">
          <v-select dense outlined clearable :items="patrones" v-model="patron" label="Filtrar por Patrón" />
        </v-col>
        <v-col>
          Unidades con GPS: {{imei.length}}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="success"
            class="mt-5"
            :disabled="loading"
            @click="nueva"
            v-if="userCan('crear-unidad')"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :items="items"
        :headers="headers"
        :search.sync="search"
        mobile-breakpoint="0"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | unidad }}
        </template>
        <template v-slot:[`item.salida`]="{ item }">
          {{ item.salida | dateTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            v-if="item.actions.edit"
            :disabled="loading"
            @click="editar(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            v-if="item.actions.historial"
            :disabled="loading"
            @click="historial(item)"
          >
            <v-icon>mdi-history</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      
    </v-card-text>
    
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Unidades",
  data: () => ({
    search: undefined,
    patron: undefined
  }),
  computed: {
    ...mapState({
      headers: state => state.Unidades.headers,
      collection: state => state.Unidades.collection,
      loading: state => state.Unidades.loader,
      user: state => state.Auth.user
    }),
    items() {
      return this.patron
        ? this.collection.filter(x => x.patron == this.patron)
        : this.collection;
    },
    patrones() {
      if (!this.collection) return [];
      let posibles = this.collection.filter(x => x.patron);
      return [...new Set(posibles.map(x => x.patron))].sort();
    },
    imei(){
      if (!this.collection) return [];
      return this.collection.filter(x=>x.imei);
    }
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("Unidades", ["getAll", "nueva", "editar", "historial"]),
    ...mapMutations("Unidades", ["SET_RESOURCE"])
  }
};
</script>
