import Index from "./../views/gastos/index.vue";
const routes = [
  {
    path: "/Gastos",
    name: "gastos",
    component: Index,
    meta: {
      name: "Gastos Últimos 6 meses"
    }
  }
];
export default routes;
