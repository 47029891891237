<template>
  <v-card height="100%">
    <v-card-text v-if="actual && !actual.importe">
      <v-row>
        <v-col v-if="icoglo">
          Última Respuesta Tracker: {{ icoglo.hora | segundos }} <br>
          {{ icoglo.resultados }} resultados
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getSalidas"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        class="elevation-1"
        :search.sync="search"
        mobile-breakpoint="0"
        :items-per-page="perPage"
        :item-class="row_classes2"
      >
        <template v-slot:[`item.hora_salida`]="{ item }">
          {{ item.hora_salida | hora }}
        </template>
        <template v-slot:[`item.programada`]="{ item }">
          {{ item.programada | hora }}
        </template>
        <template v-slot:[`item.real`]="{ item }">
          {{ item.real | hora }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="secondary"
            :disabled="loading"
            @click="detalleSalida(item.id)"
          >
            <v-icon>mdi-check-box-multiple-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.ruta`]="{ item }">
          <v-chip :color="getColor(item)">
            {{ item.ruta }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-else>
      Solo puedes ver las llegadas si tienes un turno abierto
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Llegadas",
  data: () => ({
    search: undefined,
    headers: [
      { text: "Ruta", value: "ruta" },
      { text: "Salida", value: "hora_salida" },
      { text: "Unidad", value: "unidad" },
      { text: "Operador", value: "operador" },
      { text: "LLega", value: "programada" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Quemados", value: "quemados", align: "end" },
      { text: "Directos", value: "directos", align: "end" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    perPage: 15,
    
  }),
  computed: {
    ...mapState({
      salidas: state => state.Checador.salidas,
      icoglo: state => state.Checador.icoglo,
      loading: state => state.Checador.loader,
      turnos: state => state.Checador.turnos
    }),
    actual() {
      if (!this.turnos) return undefined;
      return this.turnos.find(x => x.actual);
    },
    items() {
      if (!this.actual) return [];
      if (this.actual.importe) return [];
      return this.salidas.filter(x => x.destino == this.actual.base);
    }
  },
  mounted() {
    if (!this.turnos) this.getTurnos();
    if (!this.salidas.length) this.getSalidas();
    //this.refreshIntervalId = setInterval(() => this.getSalidas(), 300000);
  },
  methods: {
    ...mapActions("Checador", ["getSalidas", "getTurnos", "detalleSalida"]),
    row_classes2(item) {
      let color = "";
      switch (item.terminada) {
        case "SI":
          color = "green lighten-5";
          break;
        case "NO":
          color = "red lighten-4";
          break;
      }
      return color;
    },
    getColor(item) {
      if (item.ruta == "META-RET") return "green lighten-3";
      if (item.ruta == "RET-HAC") return "yellow lighten-3";
      if (item.ruta == "HAC-RET") return "green lighten-4";
      if (item.ruta == "RET-META") return "deep-purple lighten-4";
      else return "white";
    }
  }
};
</script>
