<template>
  <v-card height="100%">
    <v-card-text v-if="boleto">
      <v-row class="mt-3">
        <v-col>
          Fecha: <strong>{{ boleto.created_at | fechaLarga }}</strong>
        </v-col>
        <v-col
          >Unidad: <strong>{{ boleto.unidad2 }}</strong>
        </v-col>
        <v-col>
          Boletos: <strong>{{ boleto.boletos }}</strong>
        </v-col>
        <v-col>
          Importe: <strong>{{ boleto.importe | moneda }}</strong>
        </v-col>
        <v-col>
          Supervisor: <strong>{{ boleto.supervisor }}</strong>
        </v-col>
        <v-col>
          Operador: <strong>{{ boleto.operador }}</strong>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="recuperados">
      <h4>Dinero Recuperado {{ importe | moneda }}</h4>
      <v-data-table
        dense
        hide-default-footer
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="recuperados"
        :items-per-page="recuperados.length"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="red" :disabled="loading" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="regresar">
        regresar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Detalle",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Usuario", value: "user" },
      { text: "Acciones", value: "actions", align: "end" }
    ]
  }),
  computed: {
    ...mapState({
      boleto: state => state.Boletos.resource,
      recuperados: state => state.Boletos.recuperados,
      loading: state => state.Boletos.loader
    }),
    importe() {
      return window._.sumBy(this.recuperados, "importe");
    }
  },
  mounted() {
    if (!this.boleto) {
      this.$store.dispatch("Boletos/detalles", this.$route.params.id);
    }
  },
  methods: {
    regresar() {
      this.$router.push({ name: "boletos" });
    },
    eliminar(item) {
      let pregunta = `¿Deseas eliminar el pago de la unidad ${this.boleto.unidad2}?`;
      window
        .swal({
          title: pregunta,
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.$store.commit("Boletos/SET_LOADER", true);
            this.$store.commit("SET_ERRORS", []);
            window.axios
              .delete(`/boletos/eliminarAbono/${item.id}`)
              .then(resp => {
                window.swal(resp.data.message, {
                  icon: "success",
                  timer: 1000
                });
                this.$store.commit("Boletos/SET_RESOURCE", resp.data.resource);
                this.$store.commit(
                  "Boletos/SET_RECUPERADOS",
                  resp.data.recuperados
                );
                this.$store.commit(
                  "Boletos/SET_COLLECTION",
                  resp.data.collection
                );
              })
              .catch(e => this.$store.dispatch("errores", e))
              .finally(() => this.$store.commit("Boletos/SET_LOADER", false));
          }
        });
    }
  }
};
</script>
