import Reportes from "./../views/reportes/index.vue";
import Tarjetas from "./../views/reportes/ReporteTarjetas.vue";
const routes = [
  {
    path: "/Reportes",
    name: "reportes",
    component: Reportes,
    meta: {
      name: "Control Reportes"
    }
  },
  {
    path: "/reporte-tarjetas",
    name: "tarjetas-reporte",
    component: Tarjetas,
    meta: {
      name: "Reporte de Tarjetas"
    }
  }
];
export default routes;
