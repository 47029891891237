import Tarjetas from "./../views/tarjetas/index.vue";
const routes = [
  {
    path: "/Tarjetas",
    name: "tarjetas",
    component: Tarjetas,
    meta: {
      name: "Control de Tarjetas"
    }
  }
];
export default routes;
