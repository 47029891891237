import router from "../../router";
const initialState = () => ({
  login: false,
  user: {},
  abilities: [],
  catalogos: [],
  menu: [],
  notifications: [],
  loader: undefined,
  dashboard: { checadas: {} }
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_ABILITIES(state, payload) {
      state.abilities = payload;
    },
    SET_CATALOGOS(state, payload) {
      state.catalogos = payload;
    },
    SET_MENU(state, payload) {
      state.menu = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    },
    setDashboard(state, payload = {}) {
      state.dashboard = payload;
    }
  },
  actions: {
    async login({ commit, state, dispatch }) {
      commit("SET_ERRORS", [], { root: true });
      let user = state.user;
      await window.axios
        .post("auth/login", user)
        .then(resp => {
          let payload = resp.data;
          window.axios.defaults.headers.common.Authorization =
            "Bearer " + payload.access_token; // Very important to have "Bearer "
          localStorage.setItem("token", payload.access_token);
          commit("SET_LOGIN", true);
          dispatch("checkUser");
          commit("SET_ERRORS", [], { root: true });
          router.push({ name: "home" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async checkUser({ commit, dispatch }) {
      commit("SET_ERRORS", [], { root: true });
      if (!localStorage.token) {
        commit("SET_LOGIN", false);
        router.push("/").catch(() => {});
      } else {
        await window.axios
          .post("auth/me")
          .then(resp => {
            commit("SET_USER", resp.data.user);
            commit("SET_ABILITIES", resp.data.abilities);
            commit("SET_CATALOGOS", resp.data.catalogos);
            commit("SET_MENU", resp.data.menu);
            commit("SET_LOGIN", true);
          })
          .catch(e => dispatch("errores", e, { root: true }));
      }
    },
    async logout({ commit, dispatch }) {
      window.axios
        .post("auth/logout")
        .then(resp => {
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
          commit("SET_LOGIN", false);
          commit("SET_USER", {});
          localStorage.clear();
          router.push("/").catch(() => {});
          commit("SET_ERRORS", [], { root: true });
          dispatch("reset", null, { root: true });
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async tokenById({ commit, dispatch }, id) {
      await window.axios
        .post("auth/tokenById", { id })
        .then(resp => {
          let payload = resp.data;
          window.axios.defaults.headers.common.Authorization =
            "Bearer " + payload; // Very important to have "Bearer "
          localStorage.setItem("token", payload);
          commit("SET_LOGIN", true);
          dispatch("checkUser");
          commit("SET_ERRORS", [], { root: true });
          router.push({ name: "home" }).catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    async getNotifications({ commit, dispatch }) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .get("notifications")
        .then(resp => commit("setNotifications", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async readNotification({ commit, dispatch }, item) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .post(`readNotification/${item.id}`)
        .then(resp => commit("setNotifications", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteNotification({ commit, dispatch }, item) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .delete(`deleteNotification/${item.id}`)
        .then(resp => {
          commit("setNotifications", resp.data);
          window.swal(`Notificación ${item.id} eliminada`, {
            icon: "success",
            timer: 1500
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async dashboard({ commit, dispatch }) {
      commit("setLoader");
      commit("setDashboard");
      await window.axios
        .get("dashboard")
        .then(resp => commit("setDashboard", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteAllNotifications({ commit, dispatch }) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .post(`delete-all-notifications`)
        .then(resp => {
          commit("setNotifications", resp.data);
          window.swal(`Notificaciones eliminadas`, {
            icon: "success",
            timer: 1500
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async deleteReadNotifications({ commit, dispatch }) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .post(`delete-read-notifications`)
        .then(resp => {
          commit("setNotifications", resp.data);
          window.swal(`Notificaciones eliminadas`, {
            icon: "success",
            timer: 1500
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    },
    async readAll({ commit, dispatch }) {
      commit("setLoader");
      commit("setNotifications", []);
      await window.axios
        .post(`read-all-notifications`)
        .then(resp => {
          commit("setNotifications", resp.data);
          window.swal(`Notificaciones marcadas como leídas`, {
            icon: "success",
            timer: 1500
          });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("setLoader", false));
    }
  }
};
