/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "unidads";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  patrones: undefined,
  historial: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_PATRONES(state, payload) {
      state.patrones = payload;
    },
    SET_HISTORIAL(state, payload) {
      state.historial = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_COLLECTION", undefined);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("SET_PATRONES", resp.data.patrones);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async historial({ commit, dispatch }, item) {
      let id = item.id,
        nombre = `Historial de la unidad ${item.unidad}`;
      window.axios
        .get(`${url}/historial/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_HISTORIAL", resp.data.historial);
          router.push({ name: "unidad.historial", params: { id, nombre } });
        })
        .catch(e => dispatch("errores", e, { root: true }));
    },
    nueva({ commit }) {
      commit("SET_ERRORS", [], { root: true });
      commit("SET_RESOURCE", {});
      router.push({ name: "unidad.create" }).catch(() => {});
    },
    editar({ commit }, item) {
      let id = item.id,
        nombre = `Editando Unidad ${item.unidad}`;
      commit("SET_RESOURCE", item);
      commit("SET_ERRORS", [], { root: true });
      router
        .push({ name: "unidad.edit", params: { id, nombre } })
        .catch(() => {});
    },
    async sendUnidad({ commit, dispatch, state }) {
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      let unidad = state.resource;
      if (!unidad.id) {
        window.axios
          .post(url, unidad)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            router.push({ name: "unidades" }).catch(() => []);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      } else {
        window.axios
          .put(`${url}/${unidad.id}`, unidad)
          .then(resp => {
            window.swal(resp.data.message, { icon: "success", timer: 1000 });
            router.push({ name: "unidades" }).catch(() => []);
          })
          .catch(e => dispatch("errores", e, { root: true }))
          .finally(() => commit("SET_LOADER", false));
      }
    }
  }
};
