<template>
  <v-card>
    <v-card-title primary-title>
      Vueltas por Unidad
    </v-card-title>
    <v-card-text>
      <div>
        <bar-chart
          :chart-data="items"
          :options="options"
          :height="350"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "./../BarChart.js";
export default {
  name: "SalidasUnidad",
  components: { BarChart },
  props: ["items"],
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              max: 6,
              stepSize: 0.5
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  })
};
</script>
