<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="red lighten-4">
      No Reportados
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getAll"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1 mt-3"
        :headers="headers"
        :items="items"
        hide-default-footer
        :items-per-page="items.length"
        :search.sync="search"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | folio }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="red" :disabled="loading" @click="eliminar(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "NoReportados",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Checador", value: "checador" },
      { text: "Base", value: "base" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      pendientes: state => state.Minutos.collection,
      loading: state => state.Minutos.loader
    }),
    items() {
      if (!this.pendientes) return [];
      return this.pendientes.filter(x => !x.importe);
    }
  },
  mounted() {
    if (!this.pendientes) this.getAll();
  },
  methods: {
    ...mapActions("Minutos", ["getAll", "eliminarTurno"]),
    eliminar(item) {
      let dia = window
          .moment(item.fecha)
          .locale("es-MX")
          .format("dddd"),
        texto = window
          .moment(item.fecha)
          .locale("es-MX")
          .format("DD/MMM/YYYY");
      texto = texto.replace("ene.", "Ene");
      texto = texto.replace("feb.", "Feb");
      texto = texto.replace("mzo.", "Mzo");
      texto = texto.replace("abr.", "Abr");
      texto = texto.replace("may.", "May");
      texto = texto.replace("jun.", "Jun");
      texto = texto.replace("jul.", "Jul");
      texto = texto.replace("ago.", "Ago");
      texto = texto.replace("sep.", "Sep");
      texto = texto.replace("oct.", "Oct");
      texto = texto.replace("nov.", "Nov");
      texto = texto.replace("dic.", "Dic");
      dia = dia.replace("lunes", "Lunes");
      dia = dia.replace("martes", "Martes");
      dia = dia.replace("miércoles", "Miércoles");
      dia = dia.replace("jueves", "Jueves");
      dia = dia.replace("viernes", "Viernes");
      dia = dia.replace("sábado", "Sábado");
      dia = dia.replace("domingo", "Domingo");
      let mensaje = `¿Deseas eliminar el registro de ${item.checador} del día ${dia} ${texto}?`;

      window
        .swal({
          title: mensaje,
          text: "Una vez eliminado no se puede recuperar",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.eliminarTurno(item.id);
          }
        });
    }
  }
};
</script>
