<template>
  <v-card class="mb-3" v-if="salidas">
    <v-card-title primary-title>
      Salidas por día
    </v-card-title>
    <v-card-text>
      <bar-chart
        :chart-data="salidas"
        :options="options"
        :height="250"
      ></bar-chart>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import BarChart from "./../BarChart.js";
export default {
  name: "Salidas",
  components: { BarChart },
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  }),
  computed: {
    ...mapState({
      dashboard: state => state.Auth.dashboard
    }),
    salidas() {
      return this.dashboard.salidas;
    }
  },
  mounted() {
    if (!this.dashboard) this.$store.dispatch("Auth/dashboard");
  }
};
</script>
