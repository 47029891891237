<!-- eslint-disable -->
<template>
  <v-card height="100%" v-if="unidad">
    <v-form @submit.prevent="sendUnidad">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" xl="4">
            <v-select
              outlined
              dense
              small-chips
              label="Tipo de Unidad"
              :items="tipos"
              v-model="unidad.tipo_unidad"
              :error-messages="errors.tipo_unidad"
            />
          </v-col>
          <v-col cols="12" md="6" xl="4">
            <v-select
              outlined
              dense
              small-chips
              clearable
              label="Patron"
              :items="patrones"
              v-model="unidad.patron_id"
              :error-messages="errors.patron_id"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                dense
                type="text"
                label="Marca"
                v-model="unidad.marca"
                :error-messages="errors.marca"
              />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                dense
                type="text"
                label="Modelo"
                v-model="unidad.modelo"
                :error-messages="errors.modelo"
              />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
                outlined
                dense
                type="text"
                label="Placas"
                v-model="unidad.placas"
                :error-messages="errors.placas"
              />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :disabled="loading" type="submit">
          guardar
        </v-btn>
        <v-btn color="error" :disabled="loading" @click="cancelar">
          cancelar
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data: () => ({
    tipos: ["RENTERO", "SOCIO"]
  }),
  computed: {
    ...mapState({
      unidad: state => state.Unidades.resource,
      patrones: state => state.Unidades.patrones,
      loading: state => state.Unidades.loader,
      errors: state => state.errors
    })
  },
  mounted() {
    if (!this.unidad) {
      this.$router.push({ name: "unidades" }).catch(() => {});
    }
  },
  methods: {
    cancelar() {
      this.$router.push({ name: "unidades" });
    },
    ...mapActions("Unidades", ["sendUnidad"])
  }
};
</script>
