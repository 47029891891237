<template>
  <v-expansion-panel>
    <v-expansion-panel-header dark color="orange lighten-3">
      Datos de la Ruta
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card class="mt-3">
        <v-form @submit.prevent="sendRuta">
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Nombre de la ruta"
                  v-model="ruta.nombre"
                  :error-messages="errors.nombre"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  label="Abreviación"
                  v-model="ruta.abreviacion"
                  :error-messages="errors.abreviacion"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="modificado">
            <v-btn color="success" type="submit" :disabled="loading">
              guardar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Formulario",
  data: () => ({
    original: undefined,
  }),
  computed: {
    ...mapState({
      ruta: state => state.Rutas.resource,
      loading: state => state.Rutas.loader,
      errors: state => state.errors
    }),
    modificado() {
      return JSON.stringify(this.ruta) !== JSON.stringify(this.original);
    }
  },
  mounted() {
    this.original = Object.assign({}, this.ruta);
  },
  watch: {
    ruta: function(val) {
      this.original = Object.assign({}, val);
    }
  },
  methods: {
    ...mapActions("Rutas", ["sendRuta"])
  }
};
</script>
