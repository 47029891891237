import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    appName: "Zamgod Solutions",
    errors: [],
    loader: false
  },
  mutations: {
    SET_ERRORS(state, payload) {
      state.errors = payload;
    },
    setErrors(state, payload = {}) {
      state.errors = payload;
    },
    setLoader(state, payload = true) {
      state.loader = payload;
    }
  },
  actions: {
    reset({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/SET_INITIAL_STATE`);
      });
    },
    errores({ commit, dispatch }, e) {
      const status = e.response.status;
      switch (status) {
        case 403:
          window.swal("No cuentas con los permisos suficientes", {
            icon: "error",
            timer: 1000
          });
          router.push({ name: "home" });
          break;
        case 401:
          window.swal("La sesion expiró", {
            icon: "error",
            timer: 1000
          });
          dispatch("reset");
          localStorage.clear();
          window.axios.defaults.headers.common.Authorization =
            "Bearer token-falso";
          router.push("/").catch(() => {});
          break;
        case 422:
          commit("SET_ERRORS", e.response.data.errors);
          if (!e.response.data.errors) {
            window.swal(e.response.data.message, {
              icon: "error",
              timer: 2000
            });
          }
          break;
        case 404:
          window.swal("Recurso NO Localizado", { icon: "error", timer: 1000 });
          router.push({ name: "home" });
          break;
        default:
          window.swal(e.response.data.message, { icon: "error", timer: 4500 });
          break;
      }
    }
  },
  strict: false
});
