import Index from "./../views/liquidaciones/index.vue";
import Nueva from "./../views/liquidaciones/nueva.vue";
const routes = [
  {
    path: "/Liquidaciones",
    name: "liquidaciones",
    component: Index,
    meta: {
      name: "Liquidaciones elaboradas en los últimos 3 días"
    }
  },
  {
    path: "/Liquidaciones/nueva",
    name: "liquidaciones.create",
    component: Nueva,
    meta: {
      name: "Nueva Liquidación"
    }
  }
];
export default routes;
