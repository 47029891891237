/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "reportes";
const initialState = () => ({
  resultados: {},
  loader: false
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_RESULTADOS(state, payload) {
      state.resultados = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    }
  },
  actions: {
    async getFinanciero({ commit, dispatch }, data) {
      commit("SET_RESULTADOS", {});
      commit("SET_LOADER", true);
      commit("SET_ERRORS", [], { root: true });
      await window.axios
        .post(`${url}/financiero`, data)
        .then(resp => commit("SET_RESULTADOS", resp.data))
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
