<template>
  <v-card height="100%" v-if="ruta">
    <v-expansion-panels multiple>
      <Datos />
      <Checadas v-if="ruta.id" />
    </v-expansion-panels>
    <v-card-actions>
      <v-btn color="primary" @click="cancelar" :disabled="loading">
        regresar a rutas
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Datos from "./detalle";
import Checadas from "./checadas";
export default {
  name: "Detalles",
  components: { Datos, Checadas },
  computed: {
    ...mapState({
      ruta: state => state.Rutas.resource,
      loading: state => state.Rutas.loader
    })
  },
  mounted() {
    if (!this.ruta) {
      this.$router.push({ name: "rutas" }).catch(() => {});
    }
  },
  methods: {
    ...mapActions("Rutas", ["cancelar"])
  }
};
</script>
