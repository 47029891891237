var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user.id)?_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_vm._v(" Bienvenido "+_vm._s(_vm.user.full_name)+" "),(
        _vm.user.email == 'developer@zamgod.mx' ||
          _vm.user.email == 'ricardo@alberca.com'
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"2"}},[_c('v-checkbox',{staticClass:"mr-5",attrs:{"label":"Checadas Manuales"},model:{value:(_vm.manual),callback:function ($$v) {_vm.manual=$$v},expression:"manual"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"2"}},[_c('v-checkbox',{staticClass:"mr-5",attrs:{"label":"Gráficos"},model:{value:(_vm.graficos),callback:function ($$v) {_vm.graficos=$$v},expression:"graficos"}})],1),(_vm.notifications.length)?_c('v-col',{attrs:{"cols":"12","xl":"2"}},[_c('v-checkbox',{staticClass:"mr-5",attrs:{"label":"Notificaciones"},model:{value:(_vm.notificaciones),callback:function ($$v) {_vm.notificaciones=$$v},expression:"notificaciones"}})],1):_vm._e(),_c('v-col',{staticClass:"mt-4"},[_c('strong',[_vm._v("Hora Actualización: "+_vm._s(_vm._f("dateTime")(_vm.hora)))])])],1):_vm._e()],1),(_vm.notificaciones)?_c('Notifications'):_vm._e(),(
      _vm.user.email == 'developer@zamgod.mx' ||
        _vm.user.email == 'ricardo@alberca.com'
    )?_c('v-card-text',[_c('v-row',[(_vm.manual)?_c('v-col',{attrs:{"cols":"12","md":"6","xl":"12"}},[_c('Manuales')],1):_vm._e(),(_vm.graficos)?[_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('Checadas')],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('Porcentaje')],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('Salidas')],1),_c('v-col',{attrs:{"cols":"12","md":"6","xl":"3"}},[_c('Unidades')],1),(_vm.dashboard.vueltas)?_c('v-col',{attrs:{"cols":"12"}},[_c('Vueltas',{attrs:{"items":_vm.vueltas,"options":_vm.options}})],1):_vm._e()]:_vm._e()],2)],1):_vm._e(),(
      _vm.user.email == 'developer@zamgod.mx' ||
        _vm.user.email == 'ricardo@alberca.com'
    )?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loader},on:{"click":_vm.actualizar}},[_vm._v(" actualizar ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }