/* eslint-disable no-unused-vars */
import router from "../../router";
const url = "salidas";
const initialState = () => ({
  headers: undefined,
  collection: undefined,
  resource: undefined,
  loader: false,
  checadas: undefined,
  time: undefined,
  icoglo: undefined,
  resultados: undefined,
  search: undefined,
  ruta: undefined,
  unidad: undefined,
  rutas: undefined,
  unidades: undefined
});
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setRutas(state, payload) {
      state.rutas = payload;
    },
    setUnidades(state, payload) {
      state.unidades = payload;
    },
    SET_INITIAL_STATE(state) {
      const newState = initialState();
      Object.keys(newState).forEach(key => {
        state[key] = newState[key];
      });
    },
    SET_HEADERS(state, payload) {
      state.headers = payload;
    },
    SET_COLLECTION(state, payload) {
      state.collection = payload;
    },
    SET_RESOURCE(state, payload) {
      state.resource = payload;
    },
    SET_LOADER(state, payload) {
      state.loader = payload;
    },
    SET_CHECADAS(state, payload) {
      state.checadas = payload;
    },
    SET_TIME(state, payload) {
      state.time = payload;
    },
    SET_ICOGLO(state, payload) {
      state.icoglo = payload;
    },
    SET_RESULTADOS(state, payload) {
      state.resultados = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
    setRuta(state, payload) {
      state.ruta = payload;
    },
    setUnidad(state, payload) {
      state.unidad = payload;
    }
  },
  actions: {
    async getAll({ commit, dispatch }) {
      commit("SET_LOADER", true);
      window.axios
        .get(url)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          commit("SET_HEADERS", resp.data.headers);
          commit("SET_TIME", new Date());
          commit("SET_ICOGLO", resp.data.icoglo);
          commit("SET_RESULTADOS", resp.data.resultados);
          commit("setRutas", resp.data.rutas);
          commit("setUnidades", resp.data.unidades);
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async getSalida({ commit, dispatch }, id) {
      window.axios
        .get(`${url}/${id}`)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
          let nombre = `Detalle de la salida ${resp.data.resource.id}`;
          router
            .push({ name: "salida.details", params: { id, nombre } })
            .catch(() => {});
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async updateSalida({ commit, dispatch, state }) {
      let salida = state.resource;
      commit("SET_LOADER", true);
      await window.axios
        .put(`${url}/${salida.id}`, salida)
        .then(resp => {
          commit("SET_RESOURCE", resp.data.resource);
          commit("SET_CHECADAS", resp.data.checadas);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    },
    async eliminarSalida({ commit, dispatch }, id) {
      commit("SET_LOADER", true);
      await window.axios
        .delete(`${url}/${id}`)
        .then(resp => {
          commit("SET_COLLECTION", resp.data.collection);
          window.swal(resp.data.message, { icon: "success", timer: 1000 });
        })
        .catch(e => dispatch("errores", e, { root: true }))
        .finally(() => commit("SET_LOADER", false));
    }
  }
};
