<template>
  <v-card>
    <v-card-title primary-title>
      Unidades por Día
    </v-card-title>
    <v-card-text>
      <div>
        <bar-chart
          :chart-data="unidades"
          :options="options"
          :height="350"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "./../BarChart.js";
export default {
  name: "Unidades",
  components: { BarChart },
  props: ["unidades"],
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 10
            }
          }
        ]
      },
      maintainAspectRatio: false
    }
  })
};
</script>
