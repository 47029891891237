<template>
  <div>
    <v-row>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" xl="4" offset-xl="4">
        <v-container>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Busqueda"
            single-line
            hide-details
          />
        </v-container>
      </v-col>
    </v-row>
    <v-data-table
      dense
      mobile-breakpoint="0"
      :headers="headers"
      :items="items"
      class="elevation-1"
      :search.sync="search"
      :item-class="getClasses"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id | folio }}
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{ item.fecha | date }}
      </template>
      <template v-slot:[`item.importe`]="{ item }">
        {{ item.importe | moneda }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "TarjetasPagadas",
  data: () => ({
    headers: [
      { text: "Folio", value: "id" },
      { text: "Fecha", value: "fecha" },
      { text: "Concepto", value: "concepto" },
      { text: "Importe", value: "importe", align: "end" },
      { text: "Usuario", value: "user" }
    ],
    search: undefined
  }),
  computed: {
    ...mapState({
      items: state => state.Tarjetas.resultados.pagadas
    })
  },
  methods: {
    getClasses(item) {
      let clase = null;
      if (item.atrasado) clase = "red lighten-5";
      return clase;
    }
  }
};
</script>
