<template>
  <v-card height="100%">
    <v-expansion-panels multiple>
      <Semana />
      <NoReportados />
      <Pendientes />
      <Pagados />
    </v-expansion-panels>
  </v-card>
</template>

<script>
import Semana from "./semana";
import Pendientes from "./pendientes";
import Pagados from "./pagados";
import NoReportados from "./noReportados";
import { mapActions, mapState } from "vuex";
export default {
  name: "Minutos",
  components: { Semana, Pendientes, Pagados, NoReportados }
};
</script>
