<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="green lighten-4">
      Pagados (Últimos 6 meses)
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4">
          <v-btn
            small
            color="info"
            class="mt-5 ml-3"
            @click="getPagados"
            :disabled="loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" xl="4" offset-xl="4">
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda"
              single-line
              hide-details
            />
          </v-container>
        </v-col>
      </v-row>
      <v-data-table
        dense
        mobile-breakpoint="0"
        class="elevation-1"
        :headers="headers"
        :items="items"
        :search.sync="search"
      >
        <template v-slot:[`item.fecha_entrega`]="{ item }">
          {{ item.fecha_entrega | fechaLarga }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha | fechaLarga }}
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          {{ item.importe | moneda }}
        </template>
        <template v-slot:[`item.dinero_entregado`]="{ item }">
          {{ item.dinero_entregado | moneda }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            icon
            color="success"
            :disabled="loading"
            @click="editar(item)"
            v-if="userCan('editar-minutos')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
    <v-dialog v-model="editando" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Editar </span>
        </v-card-title>
        <v-form @submit.prevent="actualizar(turno)">
          <v-card-text>
            Fecha: {{ turno.fecha | fechaLarga }} <br />
            Checador: {{ turno.checador }} <br />
            Importe Reportado: {{ turno.importe | moneda }}
            <v-row class="mt-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  step="0.01"
                  label="Dinero Entregado"
                  v-model.number="turno.dinero_entregado"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Observaciones"
                  v-model="turno.observaciones"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="loading" type="submit">
              guardar
            </v-btn>
            <v-btn color="error" :disabled="loading" @click="cancelar">
              cancelar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Pagados",
  data: () => ({
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Checador", value: "checador" },
      { text: "Base", value: "base" },
      { text: "Reportado", value: "importe", align: "end" },
      { text: "Entregado", value: "dinero_entregado", align: "end" },
      { text: "Fecha Entrega", value: "fecha_entrega" },
      { text: "Recibió", value: "recibio" },
      { text: "Observaciones", value: "observaciones", width: "120px" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    search: undefined,
    turno: {}
  }),
  computed: {
    ...mapState({
      items: state => state.Minutos.pagados,
      loading: state => state.Minutos.loader,
      editando: state => state.Minutos.editando
    })
  },
  methods: {
    ...mapActions("Minutos", ["getPagados", "actualizar"]),
    ...mapMutations("Minutos", ["SET_EDITANDO"]),
    editar(item) {
      this.turno = Object.assign({}, item);
      this.SET_EDITANDO(true);
    },
    cancelar() {
      this.turno = {};
      this.SET_EDITANDO(undefined);
    }
  }
};
</script>
