<template>
  <v-card-text >
    <v-card>
      <v-card-title primary-title>
        Notificaciones
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              dense
              outlined
              label="Filtrar"
              v-model="filtro"
              :items="filtros"
              clearable
            />
          </v-col>
          <v-col>
            <v-text-field
              dense
              outlined
              clearable
              label="Buscar"
              v-model="search"
              append-icon="mdi-magnify"
            />
          </v-col>
          <v-col>
            <v-btn color="info" :disabled="loader" @click="actualizar">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              color="success"
              class="ml-3"
              v-if="leidas.length"
              @click="deleteRead"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              color="error"
              class="ml-3"
              v-if="notifications.length"
              @click="deleteAll"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              v-if="noLeidas.length"
              color="success"
              class="ml-3"
              @click="readAll"
            >
              <v-icon>mdi-check-box-multiple-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              dense
              mobile-breakpoint="0"
              hide-default-footer
              :headers="headers"
              :items="items"
              class="elevation-1 mt-3"
              :search.sync="search"
              :items-per-page="items.length"
            >
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateTime }}
              </template>
              <template v-slot:[`item.read_at`]="{ item }">
                {{ item.read_at | dateTime }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  icon
                  color="success"
                  @click="leer(item)"
                  :disabled="loader"
                  v-if="!item.read_at"
                >
                  <v-icon>mdi-check-box-outline</v-icon>
                </v-btn>
                <v-btn
                  icon
                  color="error"
                  @click="eliminar(item)"
                  :disabled="loader"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Notifications",
  data: () => ({
    headers: [
      { text: "Fecha", value: "created_at" },
      { text: "Notificación", value: "title" },
      { text: "Leído ", value: "read_at" },
      { text: "Acciones", value: "actions", align: "end" }
    ],
    filtro: "no",
    // filtros: ["Todas", "Leídas", "No Leídas"],
    filtros: [
      { text: "Leídas", value: "si" },
      { text: "Sin Leer", value: "no" }
    ],
    search: undefined,
    perPage: 15
  }),
  computed: {
    ...mapState({
      loader: state => state.Auth.loader,
      notifications: state => state.Auth.notifications,
      checadas: state => state.Auth.dashboard.checadas,
      total: state => state.Auth.dashboard.total
    }),
    items() {
      if (this.filtro == "si") {
        return this.notifications.filter(x => x.read_at);
      }
      if (this.filtro == "no") {
        return this.notifications.filter(x => !x.read_at);
      }
      return this.notifications;
    },
    noLeidas() {
      return this.notifications.filter(x => !x.read_at);
    },
    leidas() {
      return this.notifications.filter(x => x.read_at);
    }
  },
  beforeMount() {
    this.actualizar();
  },
  methods: {
    leer(item) {
      this.$store.dispatch("Auth/readNotification", item);
    },
    actualizar() {
      this.$store.dispatch("Auth/getNotifications");
      this.$store.dispatch("Auth/dashboard");
    },
    eliminar(item) {
      this.$store.dispatch("Auth/deleteNotification", item);
    },
    deleteAll() {
      window
        .swal({
          title: "¿Deseas eliminar TODAS las notificaciones?",
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.$store.dispatch("Auth/deleteAllNotifications");
          }
        });
    },
    deleteRead() {
      window
        .swal({
          title: "¿Deseas eliminar  las notificaciones Leídas?",
          text: "Esta acción no puede deshacerse",
          icon: "warning",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: true
        })
        .then(confirmado => {
          if (confirmado) {
            this.$store.dispatch("Auth/deleteReadNotifications");
          }
        });
    },
    readAll() {
      this.$store.dispatch("Auth/readAll");
    }
  }
};
</script>
<style>
#small {
  margin: auto;
  max-height: 300px !important;
}
</style>
