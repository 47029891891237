<!-- eslint-disable -->
<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="text-center py-5">
        <img
          :src="require('./alberca.jpg')"
          width="60%"
          alt=""
         
        />
      </div>
      <v-list dense shaped>
        <div class="d-xl-none">
          <v-list-item link :to="{ name: 'editMe' }">
            <v-list-item-action>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Mis Datos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item-group color="primary">
          <v-list-group
            :value="false"
            prepend-icon="mdi-folder"
            v-if="catalogos.length"
          >
            <template v-slot:activator>
              <v-list-item-title>Catálogos</v-list-item-title>
            </template>
            <v-list-item
              link
              :to="{ name: item.link }"
              v-for="item in catalogos"
              :key="item.title"
            >
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            :href="url"
            target="_blank"
            v-if="user.email == 'developer@zamgod.mx'"
          >
            <v-list-item-action>
              <v-icon>mdi-bug</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            :to="{ name: item.link }"
            v-for="item in menu"
            :key="item.title"
          >
            <v-list-item-action>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        class="white--text"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text" v-text="name"></v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="white--text"> {{ title }}</span>

      <v-btn
        icon
        color="white"
        link
        :to="{ name: 'editMe' }"
        class="d-none d-xl-flex"
      >
        <v-icon>mdi-account-box</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    drawer: null
  }),
  computed: {
    ...mapState({
      user: state => state.Auth.user,
      catalogos: state => state.Auth.catalogos,
      menu: state => state.Auth.menu,
      base: state => state.Checador.base,
      bases: state => state.Checador.bases
    }),
    name() {
      if (this.$route.params.nombre) return this.$route.params.nombre;
      return this.$route.meta ? this.$route.meta.name : "";
    },
    url() {
      let urlBase = process.env.VUE_APP_BASE_URL,
        url = urlBase.substring(0, urlBase.length - 4);
      return url + "log-viewer/logs";
    },
    title() {
      if (this.base) {
        let base = this.bases.find(x => x.value == this.base);
        return base.text;
      }
      return "";
    }
  }
};
</script>
