<template>
  <v-card v-if="salidas">
    <v-card-title primary-title>
      Salidas por Día
    </v-card-title>
    <v-card-text>
      <div>
        <bar-chart
          :chart-data="salidas"
          :options="options"
          :height="350"
        ></bar-chart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from "./../BarChart.js";
export default {
  name: "Salidas",
  props: ["salidas"],
  components: { BarChart },
  data: () => ({
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 20
            },
            stacked: true
          }
        ],
        xAxes: [
          {
            stacked: true
          }
        ]
      },
      maintainAspectRatio: false
    }
  })
};
</script>
